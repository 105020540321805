import React, { ChangeEvent, FC, HTMLAttributes, useEffect, useRef, useState } from 'react'
// import insuranceProviders from '../data/insurance_providers.json';
import ChevronUp from '../assets/icons/chevron-up.svg'
import { motion } from 'framer-motion'

interface SelectBoxProps extends HTMLAttributes<HTMLSelectElement | HTMLInputElement> {
    label: string
    id?: string
    options?: any
    register?: any
    setValue?: any
    dropDownTitle?: string
    setDropDownTitle?: any
    defaultValue?: string
}

export const SelectBox: FC<SelectBoxProps> = ({
    label,
    options,
    register,
    id,
    setValue,
    dropDownTitle = 'title',
    setDropDownTitle,
    defaultValue,
    ...rest
}) => {
    const [showDropDown, setShowDropDown] = useState<boolean>(false)
    const selectBoxRef = useRef(null)

    useEffect(() => {
        document.addEventListener('click', (e: any) => {
            if (!e.target.closest('.dropdown')) {
                setShowDropDown(false)
            }
        })
    }, [])

    function initDefaultValues(opts: { abbr: string; name: string }) {
        setValue(id, opts.abbr)
        setDropDownTitle(opts.name)
    }

    useEffect(() => {
        if (defaultValue) {
            setTimeout(() => {
                initDefaultValues({
                    abbr: defaultValue,
                    name: options.filter((opt: any) => opt.abbreviation === defaultValue)[0].name,
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])

    const handleOptionChange = (e: ChangeEvent<HTMLInputElement>, longName: string) => {
        setValue(id, e.target.value)
        setDropDownTitle(longName)
        setShowDropDown(false)
    }

    return (
        <div className='dropdown relative'>
            <p
                className='flex h-16 w-full cursor-pointer items-center rounded-xl border border-slate-200 bg-white p-3 px-5 capitalize placeholder-transparent focus:border-stone-400 focus:ring-stone-400'
                onClick={() => setShowDropDown(!showDropDown)}
                ref={selectBoxRef}
            >
                {dropDownTitle}
                <span
                    className={`absolute top-1/2 right-5 -translate-y-1/2 transform transition-transform duration-300 ${
                        !showDropDown ? 'rotate-180' : ''
                    }`}
                >
                    <img src={ChevronUp} className='h-4 w-4' alt='' />
                </span>
            </p>
            {showDropDown && (
                <motion.div
                    className='absolute top-16 z-50 flex max-h-64 w-full flex-col overflow-y-scroll rounded-lg border bg-white scrollbar-thin overflow-x-hidden scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-track-gray-100 scrollbar-thumb-gray-200'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    {options.map((option: any) => (
                        <label
                            htmlFor={option.abbreviation}
                            key={option.abbreviation}
                            className='cursor-pointer p-3 odd:bg-slate-100'
                        >
                            {option.name}
                            <input
                                type='radio'
                                value={option.abbreviation}
                                id={option.abbreviation}
                                className='hidden'
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleOptionChange(e, option.name)}
                            />
                        </label>
                    ))}
                </motion.div>
            )}
        </div>
    )
}
