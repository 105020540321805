// import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
// import { FormDataContext } from '../claim-kits/context/form-data-context';

export const useRedirect = () => {
    // let redirectLink = '';
    // const { gatheredInformations } = useContext(FormDataContext);
    const location = useLocation()

    return location.pathname
}
