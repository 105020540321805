import ReactLoading from 'react-loading'

export const SpinnerLoader = () => {
    return (
        <div className='flex flex-col items-center gap-y-2'>
            <ReactLoading type='spin' color='#000' height={24} width={24} />
            <p>Processing...</p>
        </div>
    )
}
