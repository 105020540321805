import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Navbar } from './shared/navbar'
import { FormDataContextProvider } from './form-data-context-provider'
import { Main } from './shared/main'

export const ClaimKitsPage = () => {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/health-plan', { replace: true })
        }
    }, [location.pathname, navigate])

    return (
        <FormDataContextProvider>
            <div className='relative min-h-screen bg-[#F5F5F4]'>
                <Navbar backBtn={true} />
                <Main />
            </div>
        </FormDataContextProvider>
    )
}
