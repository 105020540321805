import { createContext } from 'react'

export interface FormDataTypes {
    firstName: string
    lastName: string
    phoneNumber: string
    emailAddress: string
    gender: string
    birthDate: string
    isMedicareRecipient: string
    medicareNumber: string
    streetAddress: string
    aptSuite: string
    city: string
    state: string
    zipCode: string
    availability: string
    consent_sms: boolean
    consent_terms: boolean
    consent_subscription: boolean
    consent_informed: boolean
}

export const formData: FormDataTypes = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    gender: 'M',
    birthDate: '01/01/2000',
    isMedicareRecipient: '',
    medicareNumber: '',
    streetAddress: '',
    aptSuite: '',
    city: '',
    state: '',
    zipCode: '',
    availability: '',
    consent_sms: false,
    consent_terms: false,
    consent_subscription: false,
    consent_informed: false,
}

export const FormDataContext = createContext<any>({})
