import { useEffect, useRef } from 'react'
import Inputmask from 'inputmask'
import CheckMarkIcon from '../../../assets/icons/kits_icon_checkmark.svg'

type TField = {
    type: string
    id: string
    register?: any
    errors?: any
    placeholder: string
    watch?: any
    value: string
    mask?: string
    setError?: any
}

const Field = ({ type, id, register, placeholder, watch, value, errors, mask, setError, ...rest }: TField) => {
    const inputGroupRef = useRef(null)
    const { onChange, onBlur, name, ref } = register(id, {
        value: value ? value : '',
    })
    const inputWatch = watch(id)

    useEffect(() => {
        if (inputGroupRef.current) {
            const inputGroupRefCurrent = inputGroupRef.current as HTMLElement

            if (mask) {
                Inputmask({
                    regex: mask,
                    greedy: false,
                    casing: 'upper',
                }).mask(inputGroupRefCurrent.querySelector('input') as HTMLElement)
            }
        }
    }, [mask])

    if (type === 'textarea') {
        return (
            <div className='relative flex w-full items-center' ref={inputGroupRef}>
                <textarea
                    id={id}
                    className='h-48 w-full resize-none rounded-2xl border-[#d6d3d1] pl-5 placeholder-[#a8a29e]'
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    ref={ref}
                    {...rest}
                />
            </div>
        )
    }

    return (
        <div className='relative flex w-full items-center' ref={inputGroupRef}>
            <input
                type={type}
                id={id}
                className='h-12 w-full rounded-2xl border-[#d6d3d1] pl-5 placeholder-[#a8a29e]'
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                ref={ref}
                {...rest}
            />

            {errors && errors.hasOwnProperty(id) && inputWatch.length !== 0 ? null : inputWatch !== undefined &&
              inputWatch.length !== 0 ? (
                <div className='success absolute top-1/2 right-3 -translate-y-1/2 transform'>
                    <img src={CheckMarkIcon} className='h-4 w-4' alt='' />
                </div>
            ) : null}
        </div>
    )
}

export default Field
