import React, { useContext, useState } from 'react'
import { motion } from 'framer-motion'
import { formStepsTransitionVariants } from '../../../constants/form-steps-transition-variants'
import { FormDataContext, FormDataTypes } from '../context/form-data-context'
import { Typography } from '../../../components/typography'
import { UUIDContext } from '../../draft_landing/uuid-context'
import { useTranslation } from 'react-i18next'
import { useNavigateWithLang } from '../../../hooks/useNavigateWithLang'
import { createPatient, ICreatePatientResponseData } from '../create-patient'
import EditIcon from '../../../assets/icons/edit.svg'
import { Link } from 'react-router-dom'

const DEFAULT_UUID = '9800e890-4424-4abb-93fd-b1f90728c0c5'

export const CheckoutPage = () => {
    const { gatheredInformations } = useContext<{ gatheredInformations: FormDataTypes }>(FormDataContext)
    const { navigateWithLang } = useNavigateWithLang()
    const [loading, setLoading] = useState<boolean>(false)
    const UUID = useContext(UUIDContext)
    const { t } = useTranslation('forms')

    const submitRequest = async () => {
        setLoading(true)
        try {
            const data: ICreatePatientResponseData = await createPatient({
                patientData: gatheredInformations,
                UUID,
                DEFAULT_UUID,
            })

            navigateWithLang('/covid-otc/req-confirmation', data)
        } catch (err) {
            navigateWithLang('/covid-otc/req-err')
        }
    }

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[480px] flex-col'
        >
            <Typography type='h6'>{t('forms:checkout.subtitle')}</Typography>
            <Typography type='h3'>{t('forms:checkout.title')}</Typography>
            <Typography type='paragraph' className='mb-0'>
                {t('forms:checkout.description')}
            </Typography>
            <div className='mx-auto mb-6 mt-8 h-[2px] w-6/12 rounded-full bg-[#CECCC8]' />
            <div className='mb-4 flex items-center justify-between gap-x-4 rounded-[18px] border border-[#D5D3D1] bg-white py-5 pl-6 pr-6'>
                <div>
                    <img src={require('../../../assets/inbios-kit.jpg')} className='w-[92px] object-contain' alt='' />
                </div>
                <div>
                    <h4 className='mb-1 text-[14pt] font-medium first-letter:capitalize'>
                        InBios Detect™ Rapid Self-Test
                    </h4>
                    <p className='text-[12pt]'>COVID-19 Antigen Rapid Test</p>
                </div>
                <div>
                    <span className='text-[12pt] font-medium uppercase text-[#008037]'>
                        {t('forms:checkout.free_shipping_label')}
                    </span>
                </div>
            </div>
            <div className='relative grid grid-cols-12 gap-y-4 gap-x-5 rounded-[19px] border border-[#D5D3D1] bg-white px-7 py-5'>
                <div className='col-span-7'>
                    <div className='flex flex-col gap-y-1'>
                        <span className='text-[10pt] capitalize text-[#A8A29D]'>shipping address</span>
                        <span className='text-[14pt] font-medium capitalize'>{`${gatheredInformations.firstName} ${gatheredInformations.lastName}`}</span>
                        <p className='text-[12pt]'>
                            {`${gatheredInformations.streetAddress} ${gatheredInformations.aptSuite}`},
                        </p>
                        <p className='text-[12pt]'>{`${gatheredInformations.city} ${gatheredInformations.zipCode}`}</p>
                    </div>
                </div>
                <div className='col-span-5'>
                    <div className='flex flex-col gap-y-1'>
                        <span className='text-[10pt] capitalize text-[#A8A29D]'>contact info</span>
                        <span className='text-[12pt]'>{gatheredInformations.phoneNumber}</span>
                    </div>
                </div>
                <div className='absolute right-4 top-4'>
                    <Link to='/covid-otc/shipping-address'>
                        <img src={EditIcon} className='h-5 w-5' alt='' />
                    </Link>
                </div>
            </div>
            <div className='mt-9'>
                <button
                    className={`flex h-[60px] w-full items-center justify-center rounded-xl bg-kits-orange py-[18px] font-sofia-pro text-xl font-medium text-white ${
                        loading ? 'pointer-events-none cursor-wait opacity-50' : ''
                    }`}
                    onClick={submitRequest}
                >
                    {loading ? `${t('forms:checkout.loading_label')}...` : t('forms:checkout.submit_label')}
                </button>
            </div>
        </motion.div>
    )
}
