import React, { FC } from 'react'

interface CheckboxProps {
    label?: string
    name?: string
    checked?: boolean
    register: any
    badge?: string
    defaultChecked?: string
}

export const Checkbox: FC<CheckboxProps> = ({ label, name, checked, register, badge, defaultChecked }) => {
    return (
        <label className={`flex-inline relative isolate flex-grow items-center overflow-hidden rounded-2xl py-4 pr-4`}>
            <span
                className={`${
                    badge
                        ? `after:content-[${badge}] absolute top-1/2 right-6 z-50 block -translate-y-1/2 transform text-xs font-semibold text-[#007F37]`
                        : ''
                }`}
            >
                {badge}
            </span>
            <input
                type='radio'
                name={name}
                className='peer relative z-20 hidden rounded-md text-purple-600 focus:ring-0'
                value={label}
                {...register(name)}
                defaultChecked={label === defaultChecked ? true : false}
            />
            <span className='relative z-20 ml-12 capitalize'>{label}</span>
            <div className='absolute top-1/2 left-5 z-50 h-4 w-4 -translate-y-1/2 transform rounded-full border-2 bg-[#F5F5F4] peer-checked:bg-[#A9A29E]' />
            <div className='absolute inset-0 z-10 rounded-xl bg-white' />
            <div className='200 pointer-events-none absolute top-0 right-0 z-50 h-full w-full rounded-2xl border peer-checked:border-2 peer-checked:border-[#D5D3D1]' />
        </label>
    )
}
