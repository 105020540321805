import { useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { formStepsTransitionVariants } from '../../constants/form-steps-transition-variants'
import XMarkIcon from '../../assets/icons/kits_icon_xmark.svg'
import { useTranslation } from 'react-i18next'
import { useNavigateWithLang } from '../../hooks/useNavigateWithLang'
import Cookies from 'js-cookie'
import axios from 'axios'

export const ReqErrorPage = () => {
    const { t } = useTranslation('req_error')
    const { getUrlWithLang } = useNavigateWithLang()
    const clickID = Cookies.get('click_id')

    useEffect(() => {
        if (clickID && process.env.NODE_ENV === 'production') {
            sendConversion(clickID)
        }
    }, [clickID])

    // Conversion Request
    async function sendConversion(clickID: string): Promise<void> {
        let requestURL = `https://www.krishetrk.com/?nid=397&transaction_id=${clickID}`
        await axios.get(requestURL)
    }

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[510px] flex-col'
        >
            <div className='mx-auto mb-6 flex h-10 w-10 items-center justify-center rounded-full bg-red-700'>
                <img src={XMarkIcon} alt='' />
            </div>
            <h3 className='mx-auto mb-4 max-w-[390px] text-center text-[19pt] font-medium first-letter:capitalize'>
                Unfortunately, we cannot process your request at this time
            </h3>
            <p className='text-center text-[13.5pt]'>{t('req_error:description')} </p>
            <p className='mt-6 text-center text-[13.5pt]'>
                If you only have Medicare Part A or have private insurance, you may still qualify for free OTC COVID-19
                tests through other government-led programs. To learn more, visit{' '}
                <a href='https://www.covid.gov/tests' className='underline'>
                    covidtests.gov.
                </a>
            </p>
            <div className='mx-auto mt-8 mb-6 h-0.5 w-56 rounded-full bg-[#CECCC8]' />
            <div className='mx-auto w-[392px]'>
                <p className='text-center text-[12pt]'>{t('req_error:share_label')} </p>
            </div>
            <div className='mt-7 flex justify-center gap-x-5 px-6'>
                <Link
                    to={getUrlWithLang('/covid-otc/health-plan')}
                    className='flex h-10 items-center justify-center rounded-full border border-[#A8A29D] bg-[#E8E0D2] px-6'
                >
                    {t('req_error:reapply_label')}
                </Link>
            </div>
        </motion.div>
    )
}
