import React, { FC } from 'react'

interface TypographyProps {
    type: 'h3' | 'h6' | 'paragraph'
    children: any
    className?: string
}

export const Typography: FC<TypographyProps> = ({ type, children, className }) => {
    if (type === 'paragraph') {
        return <p className={`mb-8 text-center text-lg ${className}`}>{children}</p>
    }

    if (type === 'h6') {
        return (
            <h6 className={`mb-2 text-center text-sm font-semibold tracking-wide text-[#A8A29E] ${className}`}>
                {children}
            </h6>
        )
    }

    if (type === 'h3') {
        return (
            <h3
                className={`mb-4 text-center font-sofia-pro text-[26px] font-semibold first-letter:capitalize ${className}`}
            >
                {children}
            </h3>
        )
    }

    return null
}
