import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import states from '../data/states.json'
import { AnimatePresence, motion } from 'framer-motion'
import ChevronUp from '../assets/icons/chevron-up.svg'

interface IOption {
    name: string
    abbreviation: string
}

export const Blocks = () => {
    return (
        <div className='flex min-h-screen items-center justify-center bg-gray-50'>
            <div className='-mt-44'>
                <SelectBox options={states} name='states' />
            </div>
        </div>
    )
}

const SelectBox: FC<{ options: IOption[]; name: string }> = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState<IOption | null>(null)
    const [searchKeyWord, setSearchKeyWord] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0)
    const filteredOptions = props.options.filter(
        (option) =>
            option.name.toLowerCase().includes(searchKeyWord.toLowerCase()) ||
            option.abbreviation.toLowerCase().includes(searchKeyWord.toLowerCase())
    )
    const selectBoxRef = useRef(null)
    const optionsContainerRef: RefObject<HTMLDivElement> = useRef(null)
    const [lastNavigationArrow, setLastNavigationArrow] = useState<'ArrowUp' | 'ArrowDown' | null>(null)

    const navigateBetweenOptionsWithKeyboard = useCallback(
        (e: KeyboardEvent) => {
            switch (e.key) {
                case 'ArrowUp':
                    if (currentIndex <= 0) {
                        return
                    }
                    setCurrentIndex((currentIndex) => currentIndex - 1)
                    document.querySelector('.is-focused')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start',
                    })
                    if (lastNavigationArrow !== 'ArrowUp') {
                        setLastNavigationArrow('ArrowUp')
                    }
                    break

                case 'ArrowDown':
                    if (currentIndex >= filteredOptions.length - 1) {
                        return
                    }
                    setCurrentIndex((currentIndex) => currentIndex + 1)
                    document.querySelector('.is-focused')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'start',
                    })
                    if (lastNavigationArrow !== 'ArrowDown') {
                        setLastNavigationArrow('ArrowDown')
                    }
                    break

                case 'Enter':
                    const t = filteredOptions.find((_, i) => i === currentIndex)
                    if (t) {
                        setSelectedOption(t)
                        setSearchKeyWord(t.name)
                        setIsOpen(false)
                    }
                    break
            }
        },
        [currentIndex, filteredOptions, lastNavigationArrow]
    )

    const outsideClickHandler = useCallback(
        (e: MouseEvent) => {
            const target = e.target as HTMLElement

            if (target && !target.closest('.select-box') && isOpen) {
                const doesSearchExistAmongOptions = filteredOptions.find(
                    (ele) => ele.name.toLowerCase() === searchKeyWord.toLowerCase()
                )

                setIsOpen(false)
                setCurrentIndex(0)

                if (doesSearchExistAmongOptions) {
                    setSelectedOption(doesSearchExistAmongOptions)
                    setSearchKeyWord(doesSearchExistAmongOptions.name)
                } else {
                    setSelectedOption(null)
                    setSearchKeyWord('')
                }
            }
        },
        [filteredOptions, isOpen, searchKeyWord]
    )

    useEffect(() => {
        document.addEventListener('click', outsideClickHandler)

        return () => {
            document.removeEventListener('click', outsideClickHandler)
        }
    }, [isOpen, outsideClickHandler, selectedOption])

    useEffect(() => {
        document.addEventListener('keyup', navigateBetweenOptionsWithKeyboard)

        return function clear() {
            document.removeEventListener('keyup', navigateBetweenOptionsWithKeyboard)
        }
    }, [navigateBetweenOptionsWithKeyboard])

    function selectChangeHandler(v: IOption): void {
        setSearchKeyWord(v.name)
        setSelectedOption(v)
        setIsOpen(false)
    }

    return (
        <div className='select-box relative w-[220px]' ref={selectBoxRef}>
            <div
                className={`relative flex h-16 select-none items-center overflow-hidden rounded-xl border-2 bg-white px-4 transition-all ${
                    isOpen ? 'border-stone-300' : ''
                }`}
            >
                <input
                    type='text'
                    className='absolute top-0 left-0 block h-full w-full overflow-hidden text-ellipsis whitespace-nowrap border-none pr-10'
                    placeholder='Chose option'
                    value={searchKeyWord}
                    onChange={(e) => {
                        setSearchKeyWord(e.target.value)
                        optionsContainerRef.current?.scrollTo(0, 0)
                        setIsOpen(true)
                        setCurrentIndex(0)
                    }}
                    onFocus={() => {
                        setIsOpen(true)
                        optionsContainerRef.current?.scrollTo(0, 0)
                    }}
                />
                <AnimatePresence initial={false}>
                    {!isOpen ? (
                        <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: -5 }}
                            exit={{ opacity: 0, y: 10 }}
                            transition={{ duration: 0.2, ease: 'easeInOut' }}
                            key={'closed-arrow'}
                            className={`tranform absolute top-1/2 right-5 w-[14px] -translate-y-1/2`}
                        >
                            <img src={ChevronUp} alt='' className='h-full w-full rotate-180 transform' />
                        </motion.div>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0, y: -15 }}
                            animate={{ opacity: 1, y: -5 }}
                            exit={{ opacity: 0, y: -15 }}
                            transition={{ duration: 0.2, ease: 'easeInOut' }}
                            key={'open-arrow'}
                            className={`tranform absolute top-1/2 right-5 w-[14px] -translate-y-1/2`}
                        >
                            <img src={ChevronUp} alt='' className='h-full w-full' />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <AnimatePresence exitBeforeEnter initial={false}>
                {isOpen ? (
                    <motion.div
                        key={'options'}
                        initial={{ opacity: 0, scaleY: 0.8 }}
                        animate={{ opacity: 1, scaleY: 1 }}
                        exit={{ opacity: 0, scaleY: 0.8 }}
                        transition={{ duration: 0.2, ease: 'easeInOut' }}
                        ref={optionsContainerRef}
                        className={`absolute top-[70px] flex h-[250px] w-full origin-top transform flex-col overflow-hidden rounded-lg bg-white drop-shadow-2xl scrollbar`}
                    >
                        <div className='flex flex-col gap-y-1 p-2'>
                            {filteredOptions.length > 0 ? (
                                filteredOptions.map((option, i) => (
                                    <Option
                                        key={option.abbreviation}
                                        id={option.abbreviation}
                                        label={option.name}
                                        value={option.abbreviation}
                                        name={props.name}
                                        onChange={selectChangeHandler}
                                        isSelected={selectedOption?.name.toLowerCase() === option.name.toLowerCase()}
                                        isFocused={i === currentIndex}
                                        updateIndex={setCurrentIndex}
                                        index={i}
                                    />
                                ))
                            ) : (
                                <div className='absolute top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-y-2 p-2 text-gray-300'>
                                    <NoDataIcon />
                                    <span>No data</span>
                                </div>
                            )}
                        </div>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </div>
    )
}

const Option: FC<{
    id: string
    label: string
    value: string
    name: string
    onChange: (v: IOption) => void
    isSelected: boolean
    isFocused: boolean
    updateIndex: (v: number) => void
    index: number
}> = (props) => {
    const labelRef = useRef<HTMLLabelElement>(null)

    return (
        <label
            htmlFor={props.id}
            key={props.id}
            ref={labelRef}
            className={`rounded-md py-2 px-3 transition-all duration-200 ease-in-out hover:bg-gray-100 ${
                props.isSelected ? 'bg-gray-200' : ''
            } ${props.isFocused ? 'is-focused bg-gray-50' : ''}`}
            onClick={() => props.onChange({ name: props.label, abbreviation: props.id })}
            onMouseEnter={() => props.updateIndex(props.index)}
        >
            <input
                id={props.id}
                type='radio'
                name={props.name}
                value={props.value}
                onChange={() =>
                    props.onChange({
                        name: props.label,
                        abbreviation: props.id,
                    })
                }
                className='hidden'
            />
            {props.label}
        </label>
    )
}

const NoDataIcon = () => (
    <svg width='30' height='30' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            className='fill-gray-300'
            d='M10.3833 11.5667L11.5667 10.3833L13.3333 12.1583L15.1 10.3917L16.275 11.5667L14.5083 13.3333L16.2833 15.1L15.1 16.2833L13.3333 14.5083L11.5667 16.275L10.3917 15.1L12.1583 13.3333L10.3833 11.5667ZM1.66667 14.1667C1.66667 14.625 2.04167 15 2.5 15H8.61667C8.84167 15.625 9.16667 16.1833 9.60833 16.6667H2.5C1.11667 16.6667 0 15.55 0 14.1667C0 13.6667 0.15 13.2 0.416667 12.8L5 4.84167V3.33333C4.54167 3.33333 4.16667 2.95833 4.16667 2.5V1.66667C4.16667 0.75 4.91667 0 5.83333 0H9.16667C9.60869 0 10.0326 0.175595 10.3452 0.488155C10.6577 0.800716 10.8333 1.22464 10.8333 1.66667V2.5C10.8333 2.95833 10.4583 3.33333 10 3.33333V4.84167L12.0833 8.48333C11.55 8.625 11.0333 8.85833 10.575 9.16667L8.33333 5.29167V1.66667H6.66667V5.29167L1.81667 13.6917C1.725 13.825 1.66667 13.9917 1.66667 14.1667Z'
        />
    </svg>
)
