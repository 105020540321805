import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { FormDataContext } from '../context/form-data-context'

export const Main = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { gatheredInformations } = useContext(FormDataContext)

    useEffect(() => {
        const { pathname } = location
        if (process.env.NODE_ENV === 'production') {
            if (pathname !== '/covid-otc/health-plan' && gatheredInformations.isMedicareRecipient === '') {
                navigate('/covid-otc/get-started')
            }
        }
    }, [location, gatheredInformations, navigate])

    return (
        <div className='absolute top-[68px] left-0 mx-auto flex min-h-[calc(100vh_-_68px)] w-full flex-col overflow-y-scroll bg-[#F5F5F4] px-4 pt-14 pb-8'>
            <Outlet />
            {/* <div className='absolute bottom-4 right-4 text-sm font-medium text-gray-800'>
        <button
          onClick={() => {
            i18n.changeLanguage('es')
            window.history.replaceState(null, '', location.pathname.replace('/en', '/es'))
          }}
        >
          ES
        </button>
        |
        <button
          onClick={() => {
            i18n.changeLanguage('en')
            window.history.replaceState(null, '', location.pathname.replace('/es', '/en'))
          }}
        >
          EN
        </button>
      </div> */}
        </div>
    )
}
