import { useState } from 'react'
import { Link } from 'react-router-dom'
import HamburgerIcon from '../../../assets/icons/hamburger.svg'
// import CoverageIcon from '../../../assets/icons/coverage.svg'
import { IconHelp, IconCircleX } from '@tabler/icons'

export const Header = () => {
    const [sidebarVisible, setSidebarVisible] = useState(false)

    return (
        <div className='relative flex w-full flex-col justify-center overflow-hidden bg-[#2B342C] bg-hero bg-cover bg-center bg-no-repeat px-4 pb-20'>
            <div
                className={`absolute top-0 left-0 z-[100] flex h-full w-full transform flex-col justify-center bg-[#2B342C] px-6 pb-14 pt-14 transition-all duration-300 ease-in-out md:w-[250px] ${
                    sidebarVisible ? 'translate-x-0' : '-translate-x-full'
                }`}
            >
                <button
                    className='absolute top-3 right-6 flex h-6 w-6 items-center justify-center'
                    onClick={() => setSidebarVisible(false)}
                >
                    <IconCircleX color='white' />
                </button>
                <ul className='flex flex-col gap-y-6 text-[14pt] !font-normal text-white'>
                    <li
                        onClick={() => setSidebarVisible(false)}
                        className='transition-all ease-out hover:text-[#E8E0D2]'
                    >
                        <Link to={''}>Home</Link>
                    </li>
                    <li className='transition-all ease-out hover:text-[#E8E0D2]'>
                        <a href='mailto:support@yourkits.com'>Contact us</a>
                    </li>
                </ul>
                <Link
                    to={'/covid-otc/health-plan'}
                    className='text-14pt mt-auto rounded-full border border-white py-3 text-center text-white transition-all ease-out hover:border-[#E8E0D2]'
                >
                    <span>Get started</span>
                </Link>
            </div>
            <div className='container relative mx-auto mt-6 mb-auto flex max-w-5xl items-center justify-between gap-y-12 text-white md:mt-16'>
                <button className='p-4' onClick={() => setSidebarVisible(!sidebarVisible)}>
                    <img className='h-6 w-6' src={HamburgerIcon} alt='' />
                </button>
                <div className='absolute left-1/2 -translate-x-1/2 transform'>
                    <Link to={'/'} className='text-3xl'>
                        <KitsLogo />
                    </Link>
                </div>
                <button className="group:hidden group relative right-0 z-[50] h-full w-10 text-xs capitalize text-[#A9A29E] after:absolute after:top-4 after:-left-[100%] after:z-50 after:h-5 after:w-[200%] after:content-[''] group-hover:block">
                    <div className=' flex h-full w-full items-center justify-end'>
                        <IconHelp color='#E8E0D2' />
                    </div>
                    <div className='pointer-events-none absolute top-[20px] -left-[170px] z-[50] translate-y-4 transform pt-4 text-white opacity-0 transition-all duration-300 group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100'>
                        <div className='rounded-lg bg-[#2B342C] py-6 px-4'>
                            <span className='text-sm text-[#A8A29D]'>Authorized Provider</span>
                            <p className='pt-2 text-lg underline'>Nabe Pharmacy</p>
                            <div className='py-2 text-sm'>
                                <p>906 W Sunrise Blvd Suite B</p>
                                <p>Fort Lauderdale, FL 33311</p>
                            </div>
                            <p className='text-[#E8E0D2]'>NPI: 1467026724</p>
                            <a
                                href='tel:+123456789'
                                className='mt-4 block w-full rounded-full bg-[#37452C] py-2 text-base text-[#BFC2C0]'
                            >
                                contact
                            </a>
                        </div>
                    </div>
                </button>
                {/* <a href="tel:+18885736687" className="border-2 border-white rounded-full px-3 py-2">
          Call now
        </a> */}
            </div>

            <div className='container relative z-10 mx-auto mb-auto mt-10 flex max-w-5xl flex-wrap gap-y-16 py-8'>
                <div className='order-2 mx-auto flex w-full max-w-2xl flex-col items-center justify-center md:order-1'>
                    {/* <h1 className="text-[#FFF9F2] font-medium text-center my-6 md:mt-12 font-sofia-pro text-5xl md:text-[38pt] max-w-[445px]">
            $0 COVID-19 tests, delivered.
          </h1> */}
                    <h1 className='my-6 max-w-[600px] text-center font-sofia-pro text-5xl font-medium text-[#FFF9F2] md:mt-12 md:text-[38pt]'>
                        We remain committed to our patients
                    </h1>
                    {/* <p className='mb-6 max-w-[480px] text-center text-xl leading-8 text-[#F7FAFC] md:mb-6 md:text-[16pt]'>
                        Medicare covered at-home COVID-19 test kits from the comfort and safety of home.
                    </p> */}
                    <p className='mb-6 max-w-[600px] text-center text-xl leading-8 text-[#F7FAFC] md:mb-6 md:text-[16pt]'>
                        See how we plan to use our technology to expand our services to make at-home testing simpler,
                        reliable, and more accessible for patients.
                    </p>
                    <div className='mt-4 flex w-full flex-col items-center justify-center gap-y-4 gap-x-4 md:w-[590px] md:flex-row'>
                        <Link
                            to={'/company-message'}
                            className='flex w-full items-center justify-center rounded-[27px] border-2 border-[#E9E0D3] bg-transparent py-[17px] font-sofia-pro text-[15pt] font-normal text-white transition-all md:w-[70%]'
                        >
                            Company Message
                        </Link>
                    </div>
                    {/* <div className='mt-4 flex w-full flex-col items-center justify-center gap-y-4 gap-x-4 md:w-[590px] md:flex-row'>
                        <Link
                            to={'/covid-otc/health-plan'}
                            className='flex w-full items-center justify-center rounded-xl bg-[#F26E40] py-[18px] font-sofia-pro text-[18pt] font-medium text-white transition-all hover:bg-[#db6f47] md:w-2/3'
                        >
                            Enroll Now
                        </Link>
                    </div> */}

                    {/* <div className='mt-10 flex gap-x-2 text-[10pt] text-white'>
                        <img className='h-10' src={CoverageIcon} alt='' />
                        <div className='flex flex-col'>
                            <p className='text-[#FFF9F2]'>Coverage Ends May 11, 2023</p>
                            <a
                                href='https://www.cms.gov/newsroom/fact-sheets/medicare-covers-over-counter-covid-19-tests'
                                className='text-sm text-[#E8E0D2] underline'
                                target='_blank'
                                rel='noreferrer'
                            >
                                See Full Announcement from CMS
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export const KitsLogo = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width={1000}
        zoomAndPan='magnify'
        viewBox='0 0 750 374.999991'
        height={500}
        preserveAspectRatio='xMidYMid meet'
        className='h-12 w-28'
    >
        <defs>
            <g />
        </defs>
        <g fill='#e9e0d3' fillOpacity={1}>
            <g transform='translate(15.083422, 310.801587)'>
                <g>
                    <path d='M 8.417969 -221.597656 C 17.511719 -220.925781 30.648438 -226.3125 30.648438 -215.535156 L 30.648438 -33.003906 C 30.648438 -26.941406 27.277344 -21.21875 21.554688 -18.523438 C 19.195312 -17.175781 16.839844 -15.828125 14.816406 -14.480469 C 9.09375 -10.441406 12.460938 0 19.195312 0 L 115.515625 0 C 122.585938 0 125.953125 -10.441406 119.890625 -14.480469 C 117.871094 -15.828125 116.1875 -17.175781 114.167969 -18.1875 C 109.117188 -21.554688 105.410156 -27.277344 105.410156 -33.003906 L 105.410156 -79.816406 C 118.882812 -81.164062 129.320312 -77.796875 138.078125 -51.863281 C 143.804688 -35.363281 147.84375 -24.585938 150.539062 -16.164062 C 154.242188 -6.398438 162.664062 0 172.765625 0 L 238.773438 0 C 245.847656 0 248.878906 -10.441406 243.152344 -14.480469 C 241.132812 -15.828125 238.4375 -16.164062 235.40625 -16.503906 C 230.019531 -16.839844 224.628906 -21.554688 222.609375 -27.953125 L 213.515625 -54.222656 C 202.066406 -88.234375 184.554688 -101.371094 154.242188 -101.371094 C 149.191406 -101.371094 143.804688 -101.03125 137.742188 -100.359375 C 155.253906 -112.820312 164.007812 -128.984375 178.152344 -128.984375 C 193.308594 -128.648438 191.625 -108.441406 217.21875 -106.085938 C 230.691406 -104.738281 242.140625 -115.515625 242.476562 -130.667969 C 243.488281 -154.242188 220.25 -167.039062 198.359375 -166.03125 C 148.519531 -164.347656 148.519531 -121.914062 105.410156 -105.746094 L 105.410156 -217.21875 C 105.410156 -247.53125 94.972656 -251.234375 68.027344 -251.234375 C 46.476562 -251.234375 20.878906 -245.847656 6.0625 -237.425781 C -1.011719 -233.386719 1.011719 -221.933594 8.417969 -221.597656 Z M 8.417969 -221.597656 ' />
                </g>
            </g>
        </g>
        <g fill='#e9e0d3' fillOpacity={1}>
            <g transform='translate(263.543677, 310.801587)'>
                <g>
                    <path d='M 8.082031 -128.648438 C 12.460938 -128.648438 18.1875 -129.660156 22.5625 -129.660156 C 27.277344 -129.660156 30.648438 -128.3125 30.648438 -122.921875 L 30.648438 -33.003906 C 30.648438 -26.941406 26.941406 -21.21875 21.554688 -18.523438 C 18.859375 -17.175781 16.503906 -15.828125 14.816406 -14.480469 C 9.09375 -10.441406 12.125 0 18.859375 0 L 116.523438 0 C 123.261719 0 126.628906 -10.441406 120.902344 -14.480469 C 118.882812 -15.828125 116.859375 -17.175781 115.175781 -18.1875 C 109.789062 -21.554688 106.421875 -27.277344 106.421875 -33.003906 L 106.421875 -124.605469 C 106.421875 -154.578125 95.644531 -158.621094 69.039062 -158.621094 C 47.484375 -158.621094 20.878906 -153.234375 5.726562 -144.476562 C -1.011719 -140.773438 0.671875 -129.320312 8.082031 -128.648438 Z M 17.511719 -220.925781 C 17.511719 -198.023438 34.351562 -184.554688 62.976562 -184.554688 C 91.601562 -184.554688 108.105469 -198.023438 108.105469 -220.925781 C 108.105469 -243.488281 91.601562 -256.621094 62.976562 -256.621094 C 34.351562 -256.621094 17.511719 -243.488281 17.511719 -220.925781 Z M 17.511719 -220.925781 ' />
                </g>
            </g>
        </g>
        <g fill='#e9e0d3' fillOpacity={1}>
            <g transform='translate(391.813847, 310.801587)'>
                <g>
                    <path d='M 18.859375 -139.761719 L 37.71875 -139.761719 L 37.71875 -46.8125 C 37.71875 -12.796875 58.261719 4.714844 96.992188 5.386719 C 148.519531 6.0625 161.988281 -14.816406 157.949219 -21.890625 C 156.9375 -23.574219 155.253906 -23.910156 152.894531 -23.910156 C 146.496094 -23.910156 135.046875 -18.523438 126.289062 -18.523438 C 119.21875 -18.523438 113.832031 -21.890625 113.832031 -34.6875 L 113.832031 -139.761719 L 143.804688 -139.761719 C 148.855469 -139.761719 152.222656 -143.128906 152.222656 -148.179688 C 152.222656 -153.570312 148.855469 -156.9375 143.804688 -156.9375 L 113.832031 -156.9375 L 113.832031 -227.324219 C 113.832031 -233.386719 109.789062 -236.753906 103.726562 -235.40625 L 67.691406 -227.996094 C 61.964844 -226.648438 57.589844 -221.933594 57.25 -215.535156 C 54.222656 -187.585938 42.097656 -157.609375 17.175781 -156.9375 L 17.511719 -156.601562 C 13.132812 -156.265625 10.441406 -152.894531 10.441406 -148.519531 L 10.441406 -148.179688 C 10.441406 -143.128906 13.808594 -139.761719 18.859375 -139.761719 Z M 18.859375 -139.761719 ' />
                </g>
            </g>
        </g>
        <g fill='#e9e0d3' fillOpacity={1}>
            <g transform='translate(552.740636, 310.801587)'>
                <g>
                    <path d='M 6.398438 -30.308594 C 18.859375 -1.011719 72.40625 5.386719 93.625 5.386719 C 153.570312 5.386719 184.554688 -15.828125 182.53125 -53.210938 C 179.839844 -109.789062 69.375 -102.714844 71.058594 -133.363281 C 71.394531 -142.121094 81.5 -146.835938 93.960938 -145.824219 C 126.289062 -143.128906 123.933594 -112.484375 150.539062 -108.777344 C 163.335938 -107.09375 174.113281 -115.515625 171.753906 -128.3125 C 168.722656 -147.507812 140.773438 -160.980469 93.285156 -163 C 40.75 -165.355469 7.074219 -144.476562 7.410156 -114.167969 C 7.746094 -60.28125 117.199219 -50.515625 117.199219 -25.257812 C 117.199219 -15.492188 109.453125 -9.429688 91.941406 -12.125 C 47.820312 -18.859375 58.261719 -56.914062 28.625 -60.957031 C 9.09375 -63.652344 -1.011719 -47.148438 6.398438 -30.308594 Z M 6.398438 -30.308594 ' />
                </g>
            </g>
        </g>
    </svg>
)
