import { FC } from 'react'

interface IProps {
  size?: string
  color?: string
  name: string
}

export const Icon: FC<IProps> = ({ size = '20', color = '#fff', name }) => {
  switch (name) {
    case 'phone':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 fill-current"
          viewBox="0 0 20 20"
          fill={color}
          width={size}
          height={size}
        >
          <path
            d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
            fill={color}
          />
        </svg>
      )

    case 'mail':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={size}
          zoomAndPan="magnify"
          viewBox="0 0 37.5 37.499999"
          height={size}
          preserveAspectRatio="xMidYMid meet"
          version="1.0"
          fill={color}
        >
          <defs>
            <clipPath id="343d04e8c4">
              <path
                d="M 1.515625 5.496094 L 35.832031 5.496094 L 35.832031 19 L 1.515625 19 Z M 1.515625 5.496094 "
                clip-rule="nonzero"
              />
            </clipPath>

            <clipPath id="5d4e614f05">
              <path
                d="M 1.515625 11 L 35.832031 11 L 35.832031 31.746094 L 1.515625 31.746094 Z M 1.515625 11 "
                clip-rule="nonzero"
              />
            </clipPath>
          </defs>

          <g clip-path="url(#343d04e8c4)">
            <path
              fill={color}
              d="M 18.582031 18.625 L 35.824219 8.097656 L 35.824219 8.050781 C 35.824219 7.691406 35.757812 7.347656 35.625 7.015625 C 35.488281 6.6875 35.296875 6.394531 35.046875 6.140625 C 34.800781 5.886719 34.511719 5.691406 34.1875 5.554688 C 33.863281 5.417969 33.523438 5.351562 33.171875 5.351562 L 3.988281 5.351562 C 3.636719 5.351562 3.296875 5.417969 2.972656 5.554688 C 2.648438 5.691406 2.359375 5.886719 2.113281 6.140625 C 1.863281 6.394531 1.671875 6.6875 1.535156 7.015625 C 1.402344 7.347656 1.335938 7.691406 1.335938 8.050781 L 1.335938 8.097656 Z M 18.582031 18.625 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
          </g>

          <g clip-path="url(#5d4e614f05)">
            <path
              fill={color}
              d="M 19.261719 21.355469 C 19.054688 21.484375 18.824219 21.550781 18.582031 21.550781 C 18.335938 21.550781 18.109375 21.484375 17.898438 21.355469 L 1.335938 11.246094 L 1.335938 29.648438 C 1.335938 30.007812 1.402344 30.351562 1.535156 30.683594 C 1.671875 31.015625 1.863281 31.304688 2.113281 31.558594 C 2.359375 31.8125 2.648438 32.007812 2.972656 32.144531 C 3.296875 32.28125 3.636719 32.351562 3.988281 32.351562 L 33.171875 32.351562 C 33.523438 32.351562 33.863281 32.28125 34.1875 32.144531 C 34.511719 32.007812 34.800781 31.8125 35.046875 31.558594 C 35.296875 31.304688 35.488281 31.015625 35.625 30.683594 C 35.757812 30.351562 35.824219 30.007812 35.824219 29.648438 L 35.824219 11.246094 Z M 19.261719 21.355469 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      )

    default:
      return null
  }
}
