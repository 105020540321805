import { formStepsTransitionVariants } from '../../../constants/form-steps-transition-variants'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '../../../components/typography'
import { motion } from 'framer-motion'
import { DropDown } from '../shared/dropdown'
import { useState } from 'react'
import Field from '../shared/field'

const getStartedFormSchema = z.object({
    orderNumber: z.string(),
    phone: z.string().regex(/\([0-9]{3}\) [0-9]{3}-[0-9]{4}/gi),
    email: z.string().email(),
})

export const Unsubscribe = () => {
    const [noticeOfHIPAA, setNoticeOfHIPAA] = useState<boolean>(false)
    const {
        register,
        watch,
        setError,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(getStartedFormSchema),
        mode: 'onChange',
        shouldFocusError: false,
    })

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[485px] flex-col'
        >
            <Typography type='h3'> Cancel Subscription </Typography>
            <Typography type='paragraph'>
                If you wish to no longer receive your test kits, please complete the short form below to unsubscribe.{' '}
            </Typography>

            <form onSubmit={(e) => e.preventDefault()} className='flex w-full flex-col items-center gap-y-6'>
                <Field
                    id='orderNumber'
                    type='text'
                    placeholder='Order Number'
                    register={register}
                    errors={errors}
                    watch={watch}
                    value=''
                />
                <Field
                    id='email'
                    type='email'
                    placeholder='Email'
                    register={register}
                    errors={errors}
                    watch={watch}
                    value=''
                />
                <Field
                    id='phone'
                    type='text'
                    placeholder='Phone Number'
                    register={register}
                    errors={errors}
                    watch={watch}
                    value=''
                    setError={setError}
                    mask='\([0-9]{3}\) [0-9]{3}\-[0-9]{4}'
                />
                <DropDown
                    headerTitle='Cancelation Policy'
                    bodyContent='I no longer wish to receive my monthly subscription of over-the-counter COVID-19 test kits. I understand that I must give at least 5 days notice prior to the next delivery date to stop the next scheduled shipment. All shipments thereafter will be effectively canceled. I understand that I can resume my subscription at any time by calling (000) 000-0000'
                    agree={noticeOfHIPAA}
                    setAgree={setNoticeOfHIPAA}
                />
                <div className='my-1 h-[2px] w-2/4 rounded-full bg-[#a8ae9e]'></div>
                <button className='h-[46px] w-11/12 rounded-2xl border-[1px] border-[#a8ae92] bg-[#e9e0d3]'>
                    Unsubscribe
                </button>
            </form>
        </motion.div>
    )
}
