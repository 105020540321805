import React, { FC, useState } from 'react'

export const FAQToggleQuestion: FC<{
  headerTitle: string
  bodyContent: string | React.ReactNode
  initialVisible?: boolean
  className?: string
}> = ({ headerTitle, bodyContent, initialVisible = false, className }) => {
  const [isVisible, setIsVisible] = useState<boolean>(initialVisible)

  return (
    <div className="flex flex-col rounded-3xl overflow-hidden mt-4 border">
      <div className="flex justify-between items-center px-5 py-4 bg-white">
        <div className={`w-[266px] ${className}`}>{headerTitle}</div>
        <button className="cursor-pointer p-3" onClick={() => setIsVisible(!isVisible)}>
          {isVisible ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
            </svg>
          )}
        </button>
      </div>
      <div className={`px-6 bg-white transition-all duration-300 ${isVisible ? 'max-h-[250px] pb-5' : 'max-h-0 pb-0'}`}>
        <p className={`bg-[#F5F5F4] py-4 px-6 rounded-3xl max-h-[177px] overflow-y-scroll`}>{bodyContent}</p>
      </div>
    </div>
  )
}
