import React, { HTMLProps, useEffect, useRef } from 'react'
import { FC } from 'react'
import Inputmask from 'inputmask'
import AutoComplete from 'react-google-autocomplete'
import { Controller } from 'react-hook-form'
import XMarkIcon from '../assets/icons/kits_icon_xmark.svg'
import CheckMarkIcon from '../assets/icons/kits_icon_checkmark.svg'

interface InputProps extends HTMLProps<HTMLInputElement> {
    type: string
    label: string
    id: string
    placeholder: string
    register?: any
    errors?: any
    watch?: any
    setError?: any
    clearErrors?: any
    mask?: string
    control?: any
    googleAddressAutoComplete?: boolean
    setValue?: any
    setDropDownTitle?: any
    optionalPlaceholder?: boolean
    className?: string
}

export const Input: FC<InputProps> = ({
    type,
    label,
    id,
    placeholder,
    register,
    errors,
    watch,
    setError,
    clearErrors,
    mask,
    value,
    googleAddressAutoComplete,
    control,
    setValue,
    setDropDownTitle,
    optionalPlaceholder,
    className,
    ...rest
}) => {
    const inputGroupRef = useRef(null)
    const { onChange, onBlur, name, ref } = register(id, {
        valueAsDate: type === 'date' ? true : false,
        value: value ? value : '',
    })
    const inputWatch = watch(id)
    const streetAddressRef = useRef<any>(null)

    useEffect(() => {
        if (streetAddressRef.current) {
            setTimeout(() => {
                streetAddressRef.current.value = value
                setValue('streetAddress', value)
            })
        }
    }, [setValue, value])

    useEffect(() => {
        if (inputGroupRef.current) {
            const inputGroupRefCurrent = inputGroupRef.current as HTMLElement

            if (mask) {
                Inputmask({
                    regex: mask,
                    greedy: false,
                    casing: 'upper',
                }).mask(inputGroupRefCurrent.querySelector('input') as HTMLElement)
            }
        }
    }, [mask])

    return (
        <div className='relative flex-grow' ref={inputGroupRef}>
            {googleAddressAutoComplete ? (
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange, onBlur } }) => (
                        <AutoComplete
                            style={{ borderWidth: 1 }}
                            options={{ types: ['street_address'], componentRestrictions: { country: 'us' } }}
                            onBlur={onBlur}
                            onChange={onChange}
                            ref={streetAddressRef}
                            onPlaceSelected={(place, inputRef, autocomplete) => {
                                const getComponentValue = (condition: string) => {
                                    return place.address_components?.filter((component) =>
                                        component.types.includes(condition)
                                    )[0]
                                }

                                const city = place.address_components?.filter((component) =>
                                    component.types.includes('locality')
                                )[0]
                                const zipCode = place.address_components?.filter((component) =>
                                    component.types.includes('postal_code')
                                )[0]
                                const state = place.address_components?.filter((component) =>
                                    component.types.includes('administrative_area_level_1')
                                )[0]
                                const address = `${getComponentValue('street_number')?.long_name} ${
                                    getComponentValue('route')?.long_name
                                }`

                                if (streetAddressRef.current) {
                                    streetAddressRef.current.value = address
                                }

                                setValue('city', city?.long_name)
                                setValue('zipCode', zipCode?.long_name)
                                setValue('state', state?.short_name)
                                setValue('streetAddress', address)
                                onChange(address)
                            }}
                            apiKey='AIzaSyDh1YK0nYAjUbT20OMJw3j3cICxay3S8D0'
                            className='peer h-16 w-full rounded-xl border-slate-200 p-3 px-5 pt-8 placeholder-transparent focus:outline-none'
                        />
                    )}
                />
            ) : (
                <input
                    type={type}
                    id={id}
                    className={`peer h-16 w-full rounded-xl border border-slate-200 p-3 px-5 pt-8 placeholder-transparent focus:border-stone-400 focus:outline-none focus:ring-stone-400 ${className}`}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    ref={ref}
                    {...rest}
                />
            )}

            <label
                htmlFor={id}
                className='pointer-events-none absolute top-0 left-0 h-full origin-left -translate-y-3 translate-x-1 scale-75 transform px-5 py-5 capitalize text-[#A8A29E] opacity-75 transition-all duration-100 ease-in-out peer-placeholder-shown:translate-y-0 peer-placeholder-shown:translate-x-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-black peer-placeholder-shown:opacity-100 peer-focus:-translate-y-3 peer-focus:translate-x-1 peer-focus:scale-75 peer-focus:text-[#A8A29E] peer-focus:opacity-75'
            >
                {label}
            </label>
            {errors && errors.hasOwnProperty(id) && inputWatch.length !== 0 ? (
                <div className='error absolute top-1/2 right-3 -translate-y-1/2 transform'>
                    <img src={XMarkIcon} className='h-4 w-4' alt='' />
                </div>
            ) : inputWatch !== undefined && inputWatch.length !== 0 ? (
                <div className='success absolute top-1/2 right-3 -translate-y-1/2 transform'>
                    <img src={CheckMarkIcon} className='h-4 w-4' alt='' />
                </div>
            ) : null}
            {optionalPlaceholder && (
                <span className='absolute right-12 top-1/2 -translate-y-1/2 transform text-[#D5D3D1]'>Optional</span>
            )}
        </div>
    )
}
