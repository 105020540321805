import { FormContainer } from '../shared/form-container'
import { Typography } from '../../../components/typography'
import XMarkIcon from '../../../assets/icons/kits_icon_xmark.svg'
import CheckMarkIcon from '../../../assets/icons/kits_icon_checkmark.svg'
import { useNavigateWithLang } from '../../../hooks/useNavigateWithLang'
import { Link, useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { handleAddressVerificationResponse, Lookup, SmartyCore } from '../../../address-verification'
import { IShippingAddress } from './shipping-address'
import { SpinnerLoader } from '../../../components/spinner-loader'
import { FormDataContext, FormDataTypes } from '../context/form-data-context'

export const ShippingAddressConfirm = () => {
    const { navigateWithLang } = useNavigateWithLang()
    const { gatheredInformations, setGatheredInformations } = useContext<{
        gatheredInformations: FormDataTypes
        setGatheredInformations: (state: Partial<FormDataTypes>) => void
    }>(FormDataContext)
    const [validatedAddress, setValidatedAddress] = useState<any>()
    const [formattedAddress, setFormattedAddress] = useState<string | null>(null)
    const [formattedValidatedAddress, setFormattedValidatedAddress] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const location = useLocation()

    useEffect(() => {
        if (location.state) {
            const state = location.state as IShippingAddress
            const addressee = gatheredInformations.firstName + gatheredInformations.lastName
            verifyAddress(state, addressee)
            const streetAddress2 = state.aptSuite.length > 0 ? `Apt ${state.aptSuite}` : ''

            // create the original formatted address
            setFormattedAddress(
                `${state.streetAddress} ${streetAddress2} ${state.city}, ${state.state} ${state.zipCode}`
            )
        }
    }, [gatheredInformations.firstName, gatheredInformations.lastName, location.state])

    async function verifyAddress(addressInformations: IShippingAddress, addressee: string) {
        try {
            const lookup = new Lookup()
            // console.log({ apt: addressInformations.aptSuite, addrss: addressInformations.streetAddress })
            lookup.addressee = addressee
            lookup.street = addressInformations.streetAddress
            lookup.street2 = addressInformations.aptSuite
            // lookup.secondary = addressInformations.aptSuite
            lookup.city = addressInformations.city
            lookup.state = addressInformations.state
            lookup.zipCode = addressInformations.zipCode
            lookup.maxCandidates = 1
            // lookup.match = 'enhanced'
            lookup.match = 'strict'

            const batch = new SmartyCore.Batch()
            batch.add(lookup)

            const result = await handleAddressVerificationResponse(batch)
            setValidatedAddress(result[0])
            setLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (validatedAddress && validatedAddress.length > 0) {
            const primaryNumber = validatedAddress[0]?.components.primaryNumber ?? ''
            const streetPredirection = validatedAddress[0]?.components.streetPredirection ?? ''
            const streetName = validatedAddress[0]?.components.streetName
            const streetSuffix = validatedAddress[0]?.components.streetSuffix ?? ''
            const streetPostdirection = validatedAddress[0]?.components.streetPostdirection ?? ''
            const secondaryDesignator = validatedAddress[0]?.components.secondaryDesignator ?? ''
            const secondaryNumber = validatedAddress[0]?.components.secondaryNumber ?? ''
            const streetAddress = `${primaryNumber} ${streetPredirection} ${streetName} ${streetSuffix} ${streetPostdirection} ${secondaryDesignator} ${secondaryNumber}`

            const city = validatedAddress[0]?.components.cityName
            const state = validatedAddress[0]?.components.state
            const zipCode = validatedAddress[0]?.components.zipCode

            // setFormattedValidatedAddress(
            //   `${validatedAddress[0]?.deliveryLine1.replace('#', 'Apt')} ${city}, ${state} ${zipCode}`
            // )
            setFormattedValidatedAddress(`${streetAddress.replace('#', 'Apt')} ${city}, ${state} ${zipCode}`)
        }
    }, [validatedAddress])

    function goToNextPage({ useOriginal = true }) {
        if (!useOriginal) {
            setGatheredInformations({
                ...gatheredInformations,
                // streetAddress:
                //   (validatedAddress[0]?.components.primaryNumber ?? '') + ' ' + validatedAddress[0]?.components.streetName,
                streetAddress: validatedAddress[0]?.deliveryLine1.replace('#', 'Apt'),
                aptSuite: validatedAddress[0]?.deliveryLine2 ?? gatheredInformations.aptSuite,
                city: validatedAddress[0]?.components.cityName,
                state: validatedAddress[0]?.components.state,
                zipCode: validatedAddress[0]?.components.zipCode,
            })
        }
        // navigateWithLang('/covid-otc/subscribe-monthly')
        navigateWithLang('/covid-otc/consent')
    }

    return (
        <FormContainer>
            <Typography type='h3'>please confirm shipping address</Typography>
            <Typography type='paragraph'>
                To ensure accurate delivery, use the recommend verified address below.
            </Typography>
            {loading ? (
                <>
                    <div className='mt-8' />
                    <SpinnerLoader />
                </>
            ) : (
                <div className='flex flex-col gap-y-4 px-6'>
                    <div className='-mx-7 flex flex-col items-start rounded-3xl border bg-white px-8 pt-8 pb-7'>
                        <div className='flex w-full flex-wrap items-center justify-between'>
                            <div className='flex flex-col items-start'>
                                <Typography type='h6' className='-mt-5'>
                                    Original address
                                </Typography>
                                <p className='w-[16rem]'>{formattedAddress}</p>
                            </div>
                            <img src={XMarkIcon} className='h-4 w-4' alt='' />
                            <button
                                onClick={() => goToNextPage({ useOriginal: true })}
                                className='mt-4 w-full rounded-xl border-2 border-[#A8A29D] bg-[#F5F5F4] px-3 py-[9px] text-[#A8A29D] md:mt-0 md:w-[140px]'
                            >
                                Use original
                            </button>
                        </div>
                    </div>

                    {formattedValidatedAddress && formattedValidatedAddress?.length > 0 && (
                        <div className='-mx-7 flex flex-col items-start rounded-3xl border bg-white px-8 pt-8 pb-7'>
                            <div className='flex w-full flex-wrap items-center justify-between'>
                                <div className='flex flex-col items-start'>
                                    <Typography type='h6' className='-mt-5'>
                                        Validated Address
                                    </Typography>
                                    <p className='w-[16rem]'>{formattedValidatedAddress}</p>
                                </div>
                                <img src={CheckMarkIcon} className='h-4 w-4' alt='' />
                                <button
                                    onClick={() => goToNextPage({ useOriginal: false })}
                                    className='mt-4 w-full rounded-xl border-2 border-[#008036] bg-[#008036] px-3 py-[9px] text-white md:mt-0 md:w-[140px]'
                                >
                                    Use Suggested
                                </button>
                            </div>
                        </div>
                    )}
                    <Link
                        to='/covid-otc/shipping-address'
                        className='mt-1 text-center text-[12pt] capitalize text-[#737373] underline'
                    >
                        change shipping address
                    </Link>
                </div>
            )}
        </FormContainer>
    )
}
