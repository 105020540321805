import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { DropDown } from '../../../components/dropdown'
import { Typography } from '../../../components/typography'
import { formStepsTransitionVariants } from '../../../constants/form-steps-transition-variants'
import { useNavigateWithLang } from '../../../hooks/useNavigateWithLang'
import { NextButton } from '../shared/next-button'
import { FormDataContext, FormDataTypes } from '../context/form-data-context'

export const ConsentFormsPage = () => {
    const { gatheredInformations, setGatheredInformations } = useContext<{
        gatheredInformations: FormDataTypes
        setGatheredInformations: (v: FormDataTypes) => void
    }>(FormDataContext)
    const { t } = useTranslation('forms')
    const { navigateWithLang } = useNavigateWithLang()

    const goToNextPage = () => {
        navigateWithLang('/covid-otc/checkout')
    }

    function updateConsentValues(values: {
        consent_sms?: boolean
        consent_terms?: boolean
        consent_informed?: boolean
    }) {
        setGatheredInformations({
            ...gatheredInformations,
            ...values,
        })
    }

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex w-full max-w-[480px] flex-col'
        >
            <Typography type='h6'>Consent</Typography>
            <Typography type='h3'>Please review our consent forms</Typography>
            <Typography type='paragraph'>
                In order for us to provide you with exceptional care, we need your consent to the forms below.
            </Typography>
            <div className='mx-auto mb-6 h-0.5 w-[50%] rounded-full bg-[#CECCC8]' />
            <section>
                <p className='mb-2 text-center text-base'>I acknowledge the receipt of the following:</p>
                <div className='flex flex-col'>
                    <DropDown
                        headerTitle={t('forms:consent.Communications.title')}
                        bodyContent={t('forms:consent.Communications.description')}
                        agree={gatheredInformations.consent_sms}
                        setAgree={(v: boolean) => {
                            updateConsentValues({ consent_sms: v })
                        }}
                        agreeText={t('forms:consent.labels.agree_label')}
                        completeText={t('forms:consent.labels.complete_label')}
                    />
                    <DropDown
                        headerTitle={t('forms:consent.Refills.title')}
                        bodyContent={t('forms:consent.Refills.description')}
                        agree={gatheredInformations.consent_terms}
                        setAgree={(v: boolean) => {
                            updateConsentValues({ consent_terms: v })
                        }}
                        agreeText={t('forms:consent.labels.agree_label')}
                        completeText={t('forms:consent.labels.complete_label')}
                    />
                    <DropDown
                        headerTitle={t('forms:consent.Terms.title')}
                        bodyContent={t('forms:consent.Terms.description')}
                        agree={gatheredInformations.consent_informed}
                        setAgree={(v: boolean) => {
                            updateConsentValues({ consent_informed: v })
                        }}
                        agreeText={t('forms:consent.labels.agree_label')}
                        completeText={t('forms:consent.labels.complete_label')}
                    />
                </div>
            </section>
            <div className='mt-9' />
            <NextButton
                isValid={
                    gatheredInformations.consent_sms &&
                    gatheredInformations.consent_terms &&
                    gatheredInformations.consent_informed
                }
                onClick={goToNextPage}
                title={t('forms:consent.labels.checkout_label')}
            />
        </motion.div>
    )
}
