import { FAQToggleQuestions } from '../draft_landing/components/FAQ'
import { Footer } from './components/footer'
import { Header } from './components/header'

export const FAQPage = () => {
    return (
        <>
            <Header />
            <div className='container mx-auto'>
                <div className='mx-auto mb-12 max-w-4xl'>
                    <h2 className='mb-6 mt-6 max-w-[485px] text-3xl leading-[50px]'>Eligibility</h2>
                    <FAQToggleQuestions />
                </div>
                <div className='mx-auto mb-12 max-w-4xl'>
                    <h2 className='mb-6 mt-6 max-w-[485px] text-3xl leading-[50px]'>Orders</h2>
                    <FAQToggleQuestions />
                </div>
            </div>
            <div className='mt-20' />
            <Footer />
        </>
    )
}
