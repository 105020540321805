import { zodResolver } from '@hookform/resolvers/zod'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { FormDataContext } from '../context/form-data-context'
import { Input } from '../../../components/input'
import { Typography } from '../../../components/typography'
import { formStepsTransitionVariants } from '../../../constants/form-steps-transition-variants'
import { motion } from 'framer-motion'
import { SelectBox } from '../../../components/select-box'
import { useNavigateWithLang } from '../../../hooks/useNavigateWithLang'

const profileFormSchema = z.object({
    gender: z.string(),
    birthDate: z.date(),
})

export const InsuranceProviderPage = () => {
    const [showProviderTextInput, setShowProviderTextInput] = useState<boolean>(false)
    const { navigateWithLang } = useNavigateWithLang()
    const {
        watch,
        register,
        formState: { isValid, errors },
    } = useForm({ resolver: zodResolver(profileFormSchema), mode: 'onChange' })
    const { gatheredInformations, setGatheredInformations } = useContext(FormDataContext)

    const goToNextPage = () => {
        const watchedInfo = watch()

        setGatheredInformations({
            ...gatheredInformations,
            gender: watchedInfo.gender,
            birthDate: new Date(watchedInfo.birthDate),
        })

        navigateWithLang('/covid-otc/health-plan')
    }

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[480px] flex-col'
        >
            <Typography type='h3'>select your health insurance provider</Typography>
            <Typography type='paragraph'>
                please pick the health insurance payor from the list of providers below
            </Typography>
            <form>
                <SelectBox
                    label='Select your health insurance provider'
                    // options={insuranceProviders}
                />
                <div className='mt-4'>
                    {showProviderTextInput ? (
                        <Input
                            label='Enter the provider name'
                            id='providerName'
                            type='text'
                            placeholder='Enter the provider name'
                            register={register}
                            errors={errors}
                            watch={watch}
                            value={gatheredInformations.birthDate}
                        />
                    ) : (
                        <button
                            className='peer h-16 w-full rounded-xl border border-slate-200 bg-white p-3 px-5 placeholder-transparent focus:outline-none'
                            onClick={() => setShowProviderTextInput(true)}
                            type='button'
                        >
                            i can't find the provider
                        </button>
                    )}
                </div>
                <div className='mt-9'>
                    <button
                        type='submit'
                        onClick={goToNextPage}
                        className={`${
                            isValid ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-50'
                        } next-link flex h-[60px] w-full items-center justify-center rounded-xl bg-[#F26E40] py-[18px] font-sofia-pro text-xl font-medium text-white`}
                    >
                        Continue
                    </button>
                </div>
            </form>
        </motion.div>
    )
}
