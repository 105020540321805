import { formStepsTransitionVariants } from '../../../constants/form-steps-transition-variants'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '../../../components/typography'
import { motion } from 'framer-motion'
import { Input } from '../../../components/input'
import { Icon } from '../../../icons/icon'
import { useEffect, useRef } from 'react'

const contactFormSchema = z.object({
    orderNumber: z.string(),
    phone: z.string().regex(/\([0-9]{3}\) [0-9]{3}-[0-9]{4}/gi),
    email: z.string().email(),
})

export const ContactPage = () => {
    const {
        register,
        watch,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(contactFormSchema),
        mode: 'onChange',
        shouldFocusError: false,
    })

    const inputGroupRef = useRef(null)
    const { onChange, onBlur, name, ref } = register('textarea')

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[430px] flex-col'
        >
            <Typography type='h3'> Contact Us </Typography>
            <Typography type='paragraph'>
                {' '}
                Complete the form below and someone from our team will contact you.{' '}
            </Typography>

            <form onSubmit={(e) => e.preventDefault()} className='flex w-full flex-col items-center gap-y-6'>
                <Input
                    label='Name'
                    id='name'
                    type='text'
                    placeholder='name'
                    register={register}
                    errors={errors}
                    watch={watch}
                    className='capitalize w-[27rem]'
                />
                
                <Input
                    label='Email'
                    id='email'
                    type='email'
                    placeholder='email'
                    register={register}
                    errors={errors}
                    watch={watch}
                    className='capitalize w-[27rem]'
                />

                <Input
                    label='Phone Number'
                    id='phone'
                    type='text'
                    placeholder='(305)  000-0000'
                    register={register}
                    errors={errors}
                    watch={watch}
                    mask='\([0-9]{3}\) [0-9]{3}\-[0-9]{4}'
                    className='w-[27rem]'
                />

                <Input
                    label='Department'
                    id='department'
                    type='text'
                    placeholder='department'
                    register={register}
                    errors={errors}
                    watch={watch}
                    className='capitalize w-[27rem]'
                />

                <div className='relative flex w-full items-center' ref={inputGroupRef}>
                    <textarea
                        id='textarea'
                        placeholder=''
                        className='h-52 w-full resize-none rounded-2xl peer border border-slate-200 focus:border-stone-400 focus:outline-none focus:ring-stone-400 pl-5 pt-8 placeholder-transparent'
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        ref={ref}
                    />

                    <label
                        htmlFor='textarea'
                        className='pointer-events-none absolute h-full origin-left -translate-y-5 translate-x-1 scale-75 transform px-5 py-5 capitalize text-[#A8A29E] opacity-75 transition-all duration-100 ease-in-out peer-placeholder-shown:translate-y-0 peer-placeholder-shown:translate-x-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-black peer-placeholder-shown:opacity-100 peer-focus:-translate-y-7 peer-focus:translate-x-1 peer-focus:scale-75 peer-focus:text-[#A8A29E] peer-focus:opacity-75'
                    >
                        Please detail your reason for contact
                    </label>
                </div>

                <button className='h-[46px] w-11/12 rounded-xl border-[1px] border-[#a8ae92] bg-[#e9e0d3]'>
                    Submit
                </button>

                <hr className='my-1 w-2/4 rounded border-[#a8ae9e]' />

                <div className='flex h-20 w-full items-center justify-around rounded-2xl border-[1px] border-[#d6d3d1] bg-white p-5'>
                    <div className='flex w-[35%] flex-col items-center gap-y-2'>
                        <p className='text-center text-sm text-[#737373]'> Phone Number </p>

                        <div className='flex items-center gap-x-2'>
                            <Icon name='phone' color='#a6a6a6' />
                            <p className='text-sm'> (800) 000-0000 </p>
                        </div>
                    </div>

                    <div className='flex w-1/2 flex-col items-center gap-y-2'>
                        <p className='text-center text-sm text-[#737373]'> Email Support </p>

                        <div className='flex items-center gap-x-2 pl-5'>
                            <Icon name='mail' color='#a6a6a6' />
                            <p className='text-sm'> support@yourkits.com </p>
                        </div>
                    </div>
                </div>
            </form>
        </motion.div>
    )
}
