import { useState } from 'react'
import { Link } from 'react-router-dom'

export const FAQSection = () => {
    return (
        <div className='container mx-auto flex flex-col py-20 px-4 lg:px-32'>
            <h5 className='text-left text-lg font-medium text-slate-700'>FAQ</h5>
            <h2 className='mb-6 mt-6 max-w-[485px] text-[44px] font-medium leading-[50px]'>Common questions</h2>
            <p className='max-w-lg text-lg'>
                Here's some of the most commonly asked questions in regards to the pricing. If we couldn't answer all
                your questions, please feel free to
                <Link to='/'>
                    <span className='inline-block pl-[4px] underline'>contact us.</span>
                </Link>
            </p>
            <FAQToggleQuestions />
        </div>
    )
}

export const FAQToggleQuestions = () => (
    <div className='mt-6 flex flex-col items-center gap-y-2'>
        <FAQToggleQuestion />
        <FAQToggleQuestion />
        <FAQToggleQuestion />
        <FAQToggleQuestion />
        <FAQToggleQuestion />
        <div className='mt-6' />
        <Link to='/'>
            <span className='block underline'>See all questions</span>
        </Link>
    </div>
)

const FAQToggleQuestion = () => {
    const [visible, setVisible] = useState<boolean>(false)

    return (
        <div className='flex flex-col'>
            <div
                className='cursor-pointer select-none bg-[#F0EBE5] px-8 py-4 text-xl font-light'
                onClick={() => setVisible(!visible)}
            >
                Who's eligible for this initiative ?
            </div>

            <div
                className={`origin-top overflow-hidden bg-[#D5D3D1] px-8 text-xl font-light transition-all duration-300 ${
                    visible ? `max-h-[200px] py-6` : 'max-h-0 py-0'
                }`}
            >
                Medicare will cover these tests if you have Part B, including those enrolled in a Medicare Advantage
                plan. If you are in a Medicare Advantage plan, the tests covered under this initiative will be covered
                outside of your existing plan's coverage, and in addition to any over-the-counter tests that may be
                covered under the plan as a supplemental benefit.
            </div>
        </div>
    )
}
