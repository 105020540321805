import axios from 'axios'
import { FormDataTypes } from './context/form-data-context'

export interface ICreatePatientResponseData {
    facilityKey: string
    patientId: string
    facilityName: string
    facilityNpi: string
    facilityPhone: string
    facilityAddress: string
}

export const createPatient = async ({
    patientData,
    UUID,
    DEFAULT_UUID,
}: {
    patientData: FormDataTypes
    UUID: any
    DEFAULT_UUID: string
}): Promise<ICreatePatientResponseData> => {
    let default_UUID: string | null

    if (process.env.NODE_ENV === 'production') {
        default_UUID = DEFAULT_UUID
    } else {
        default_UUID = null
    }

    const { data } = await axios({
        method: 'POST',
        url: '/api/patient',
        data: {
            first_name: patientData.firstName.trim(),
            last_name: patientData.lastName.trim(),
            sex: patientData.gender === 'Male' ? 'M' : 'F',
            dob: patientData.birthDate,
            email: patientData.emailAddress.length === 0 ? null : patientData.emailAddress.trim(),
            phone_number: patientData.phoneNumber,
            street_address: patientData.streetAddress.trim(),
            street_address_2: patientData.aptSuite.trim(),
            state: patientData.state.trim(),
            city: patientData.city.trim(),
            zip: patientData.zipCode.trim(),
            facility_id: 1,
            insurance_number: patientData.medicareNumber.replace(/-/gi, '').trim(),
            campaign_uuid: UUID.source ? UUID.source : default_UUID,
            consent_sms: patientData.consent_sms,
            consent_terms: patientData.consent_terms,
            // consent_subscription: patientData.consent_subscription,
            consent_subscription: false,
        },
    })

    return {
        patientId: data.data.id,
        facilityKey: data.data.facility.internal_ref_key,
        facilityName: data.data.facility.name,
        facilityNpi: data.data.facility.npi,
        facilityPhone: data.data.facility.phone,
        facilityAddress: `${data.data.facility.street_address} ${data.data.facility.street_address_2} ${data.data.facility.city}, ${data.data.facility.state} ${data.data.facility.zip}`,
    }
}
