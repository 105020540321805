import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { formStepsTransitionVariants } from '../../constants/form-steps-transition-variants'
import CheckMarkIcon from '../../assets/icons/kits_icon_checkmark.svg'
import ForwardIcon from '../../assets/icons/kits_icon_forward.svg'
import LinkIcon from '../../assets/icons/kits_icon_link.svg'
import FacebookIcon from '../../assets/icons/kits_icon_fb.svg'
import InstagramIcon from '../../assets/icons/kits_icon_ig.svg'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ICreatePatientResponseData } from './create-patient'
import Cookies from 'js-cookie'

const dummyStateResponseData: ICreatePatientResponseData = {
    facilityKey: 'OTC',
    patientId: '47374',
    facilityName: 'nabe pharmacy',
    facilityNpi: '1467026724',
    facilityPhone: '(332) 232-2333',
    facilityAddress: '2000 Example Road Suite B Miami, Florida 33032',
}

export const RequestConfirmationPage = () => {
    const { t } = useTranslation('req_confirmation')
    const location = useLocation()
    let state = location.state as ICreatePatientResponseData
    const clickID = Cookies.get('click_id')

    if (process.env.NODE_ENV === 'development') {
        state = dummyStateResponseData
    }

    useEffect(() => {
        if (clickID && process.env.NODE_ENV === 'production') {
            sendConversion(clickID)
        }
    }, [clickID])

    // Conversion Request
    async function sendConversion(clickID: string): Promise<void> {
        let requestURL = `https://www.krishetrk.com/?nid=397&transaction_id=${clickID}`
        await fetch(requestURL)
    }

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[480px] flex-col'
        >
            <div className='mx-auto mb-6 flex h-12 w-12 items-center justify-center rounded-full bg-green-700'>
                <img src={CheckMarkIcon} alt='' />
            </div>
            <h3 className='mb-4 text-center text-xl font-medium first-letter:capitalize'>
                {t('req_confirmation:title')}
            </h3>
            <p className='mb-8 max-w-[460px] text-center text-base'>{t('req_confirmation:description')}</p>

            <div className='flex justify-around gap-x-4 rounded-2xl border border-[#D5D3D1] bg-white py-5 px-2 sm:gap-x-9 sm:px-6'>
                <div>
                    <span className='text-xs text-[#A8A29D]'>{t('req_confirmation:order_date_label')}</span>
                    <p className='text-[12pt]'>{format(new Date(), 'PP')}</p>
                </div>
                <div>
                    <span className='text-xs text-[#A8A29D]'>Order No.</span>
                    <p className='text-[12pt]'>{state.facilityKey + state.patientId}</p>
                </div>
                <div>
                    <span className='text-xs text-[#A8A29D]'>{t('req_confirmation:status_label')}</span>
                    <p className='text-[12pt]'>Pending</p>
                </div>
            </div>

            <div className='mt-6 flex flex-wrap justify-around gap-x-9 rounded-2xl border border-[#D5D3D1] bg-white py-5'>
                <div className='flex w-full justify-between px-4 sm:px-6'>
                    <div className='text-left'>
                        <span className='text-xs text-[#A8A29D]'>Pharmacy</span>
                        <p className='text-[12pt] capitalize'>{state.facilityName}</p>
                    </div>
                    <a
                        href={`tel:+1${state.facilityPhone}`}
                        className='mr-6 flex h-10 items-center justify-center rounded-full border border-[#A8A29D] bg-[#E8E0D2] px-6 capitalize'
                    >
                        contact
                    </a>
                </div>

                <div className='mt-5 mb-2 h-px w-full bg-[#D3D1CF]' />

                <div className='flex w-full justify-between px-4 sm:px-8'>
                    <div className='w-7/12'>
                        <span className='text-xs text-[#A8A29D]'>location</span>
                        <p className='text-[12pt]'>{state.facilityAddress}</p>
                    </div>

                    <div className='w-4/12'>
                        <span className='text-xs text-[#A8A29D]'>NPI</span>
                        <p className='text-[12pt]'>{state.facilityNpi}</p>
                    </div>
                </div>
            </div>

            <div className='mx-auto mt-8 mb-6 h-0.5 w-56 rounded-full bg-[#A8A19E]' />

            <div className='mx-auto w-full'>
                <p className='text-center text-[11pt]'>{t('req_confirmation:share_label')}</p>
            </div>

            <ul className='mx-auto mt-6 flex gap-x-8'>
                <li>
                    <a href='#forward'>
                        <img src={ForwardIcon} className='h-8' alt='' />
                    </a>
                </li>
                <li>
                    <a href='#link'>
                        <img src={LinkIcon} className='h-8' alt='' />
                    </a>
                </li>
                <li>
                    <a href='#fb'>
                        <img src={FacebookIcon} className='h-8' alt='' />
                    </a>
                </li>
                <li>
                    <a href='#ig'>
                        <img src={InstagramIcon} className='h-8' alt='' />
                    </a>
                </li>
            </ul>
        </motion.div>
    )
}
