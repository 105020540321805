import React from 'react'
import { FC, useState } from 'react'
import { formData, FormDataContext } from './context/form-data-context'

interface FormDataContextProviderProps {
    children: JSX.Element | JSX.Element[]
}

export const FormDataContextProvider: FC<FormDataContextProviderProps> = ({ children }) => {
    const [gatheredInformations, setGatheredInformations] = useState(formData)

    return (
        <FormDataContext.Provider value={{ gatheredInformations, setGatheredInformations }}>
            {children}
        </FormDataContext.Provider>
    )
}
