import React, { useContext, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'
import { Input } from '../../../components/input'
import { Typography } from '../../../components/typography'
import { useRedirect } from '../../hooks/use-redirect'
import { FormDataContext, FormDataTypes } from '../context/form-data-context'
import states from '../../../data/states.json'
import { NextButton } from '../shared/next-button'
import { useTranslation } from 'react-i18next'
import { useNavigateWithLang } from '../../../hooks/useNavigateWithLang'
import { FormContainer } from '../shared/form-container'
import { Select } from '../../../components/select'

export interface IShippingAddress {
    streetAddress: string
    aptSuite: string
    city: string
    state: string
    zipCode: string
}

const ShippingAddressFormSchema = z.object({
    streetAddress: z.string().min(5),
    aptSuite: z.string(),
    city: z.string().min(2),
    state: z.string().min(2),
    zipCode: z.string().min(3),
})

export const ShippingAddressPage = () => {
    const { gatheredInformations, setGatheredInformations } = useContext<{
        gatheredInformations: FormDataTypes
        setGatheredInformations: (state: Partial<FormDataTypes>) => void
    }>(FormDataContext)
    const {
        register,
        watch,
        control,
        formState: { errors, isValid },
        setValue,
    } = useForm({
        resolver: zodResolver(ShippingAddressFormSchema),
        mode: 'onChange',
        shouldFocusError: false,
        defaultValues: {
            state: gatheredInformations.state,
            streetAddress: gatheredInformations.streetAddress,
            aptSuite: gatheredInformations.aptSuite,
            city: gatheredInformations.city,
            zipCode: gatheredInformations.zipCode,
        },
    })
    const [isStateSelected, setIsStateSelected] = useState<boolean>(true)
    const redirectLink = useRedirect()
    const location = useLocation()
    const { t } = useTranslation('forms')
    const { navigateWithLang } = useNavigateWithLang()

    useEffect(() => {
        if (location.pathname !== redirectLink) {
            navigateWithLang(redirectLink)
        }
    }, [location.pathname, navigateWithLang, redirectLink])

    const goToNextPage = () => {
        if (!isValid || !isStateSelected) return
        const watchedInfo = watch()
        const addressInformations: IShippingAddress = {
            streetAddress: watchedInfo.streetAddress,
            aptSuite: watchedInfo.aptSuite,
            city: watchedInfo.city,
            state: states.find((state) => state.name === watchedInfo.state)!.abbreviation,
            zipCode: watchedInfo.zipCode,
        }

        setGatheredInformations({
            ...gatheredInformations,
            ...addressInformations,
        })
        navigateWithLang('/covid-otc/shipping-address-confirm', addressInformations)
    }

    return (
        <FormContainer>
            <Typography type='h6'>{t('forms:shipping_address.subtitle')}</Typography>
            <Typography type='h3'>{t('forms:shipping_address.title')}</Typography>
            <Typography type='paragraph'>{t('forms:shipping_address.description')}</Typography>
            <form className='flex flex-col gap-y-3' onSubmit={(e) => e.preventDefault()}>
                <Input
                    label={t('forms:shipping_address.street_address_label')}
                    id='streetAddress'
                    type='text'
                    placeholder={t('forms:shipping_address.street_address_label')}
                    register={register}
                    errors={errors}
                    watch={watch}
                    value={gatheredInformations.streetAddress}
                    control={control}
                    setValue={setValue}
                />
                <Input
                    label='Apt / Suite'
                    id='aptSuite'
                    type='text'
                    placeholder='Apt / Suite'
                    register={register}
                    errors={errors}
                    watch={watch}
                    value={gatheredInformations.aptSuite}
                />
                <Input
                    label={t('forms:shipping_address.city_label')}
                    id='city'
                    type='text'
                    placeholder={t('forms:shipping_address.city_label')}
                    register={register}
                    errors={errors}
                    watch={watch}
                    value={gatheredInformations.city}
                />
                <div className='mb-3 flex flex-col gap-y-4 gap-x-4 pb-4 md:flex-row'>
                    <div className='w-full md:w-1/2'>
                        <Controller
                            name='state'
                            control={control}
                            render={({ field }) => (
                                <Select
                                    options={states}
                                    name='state'
                                    value={field.value}
                                    onChange={field.onChange}
                                    isSelected={isStateSelected}
                                    setIsSelected={setIsStateSelected}
                                />
                            )}
                        />
                    </div>
                    <div className='w-full md:w-1/2'>
                        <Input
                            label={t('forms:shipping_address.zip_code_label')}
                            id='zipCode'
                            type='number'
                            placeholder='zip code'
                            register={register}
                            errors={errors}
                            watch={watch}
                            value={gatheredInformations.zipCode}
                        />
                    </div>
                </div>
                <div className='mt-0 md:mt-9' />
                <NextButton
                    isValid={isValid && isStateSelected}
                    onClick={goToNextPage}
                    title={t('forms:common.continue_btn')}
                />
            </form>
        </FormContainer>
    )
}
