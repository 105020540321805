import { FC } from 'react'
import { motion } from 'framer-motion'
import { formStepsTransitionVariants } from '../../../constants/form-steps-transition-variants'

export const FormContainer: FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[520px] flex-col'
        >
            {children}
        </motion.div>
    )
}
