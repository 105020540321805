import React, { FC, useState } from 'react'
import CheckMarkIcon from '../assets/icons/kits_icon_checkmark.svg'

interface DropDownProps {
    headerTitle: string
    bodyContent: any
    agree: boolean
    setAgree: (v: boolean) => void
    agreeText: string
    completeText: string
}

export const DropDown: FC<DropDownProps> = ({ headerTitle, bodyContent, agree, setAgree, agreeText, completeText }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false)

    return (
        <div className='mt-4 flex flex-col overflow-hidden rounded-lg border'>
            <div className='flex items-center justify-between bg-white px-5 py-6'>
                <div className='w-[266px]'>{headerTitle}</div>
                <button className='cursor-pointer p-3' onClick={() => setIsVisible(!isVisible)}>
                    {isVisible ? (
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth={2}
                        >
                            <path strokeLinecap='round' strokeLinejoin='round' d='M18 12H6' />
                        </svg>
                    ) : (
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-4 w-4'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                        >
                            <path strokeLinecap='round' strokeLinejoin='round' d='M12 4v16m8-8H4' />
                        </svg>
                    )}
                </button>
                <button
                    className={`agreement-btn ml-auto h-9 rounded-full px-4 transition-all duration-200 ${
                        agree
                            ? 'text-[#008036] transition-none'
                            : 'border-1 border border-[#A8A29D] text-[#78716C] hover:bg-slate-50'
                    }`}
                    onClick={() => setAgree(!agree)}
                >
                    {agree ? (
                        <span className='flex items-center gap-x-2 font-medium'>
                            Agreed
                            <img src={CheckMarkIcon} alt='' className='h-4 w-4' />
                        </span>
                    ) : (
                        'Accept'
                    )}
                </button>
            </div>
            <div
                className={`bg-white px-2 transition-all duration-300 ${
                    isVisible ? 'max-h-[250px] pb-4' : 'max-h-0 pb-0'
                }`}
            >
                <p className={`max-h-[177px] overflow-y-scroll rounded-3xl bg-[#F5F5F4] py-4 px-6`}>{bodyContent}</p>
            </div>
        </div>
    )
}
