import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'

interface NextButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    isValid: boolean
    title?: string
    className?: string
}

export const NextButton: FC<NextButtonProps> = ({ isValid, title = 'Continue', className, ...rest }) => {
    return (
        <button
            type='submit'
            className={`${
                isValid ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-50'
            } next-link flex h-[60px] w-full items-center justify-center rounded-xl bg-[#F26E40] py-[18px] font-sofia-pro text-xl font-medium text-white ${className}`}
            {...rest}
        >
            {title}
        </button>
    )
}
