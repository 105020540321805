import SmartySDK from 'smartystreets-javascript-sdk'
export const SmartyCore = SmartySDK.core
export const Lookup = SmartySDK.usStreet.Lookup

let key = process.env.REACT_APP_SMARTY_EMBEDDED_KEY ?? ''
const credentials = new SmartyCore.SharedCredentials(key)

let clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-core-cloud'])
let client = clientBuilder.buildUsStreetApiClient()

function handleSuccess(response: any) {
    return response.lookups.map((lookup: any) => lookup.result)
}

function handleError(response: any) {
    return response
}

export async function handleAddressVerificationResponse(lookup: any) {
    try {
        const result = await client.send(lookup)
        return handleSuccess(result)
    } catch (err) {
        return handleError(err)
    }
}
