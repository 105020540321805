import React, { useContext } from 'react'
import { Input } from '../../../components/input'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormDataContext } from '../context/form-data-context'
import { Typography } from '../../../components/typography'
import { motion } from 'framer-motion'
import { formStepsTransitionVariants } from '../../../constants/form-steps-transition-variants'
import { NextButton } from '../shared/next-button'
import { useNavigateWithLang } from '../../../hooks/useNavigateWithLang'
import { useTranslation } from 'react-i18next'

const getStartedFormSchema = z.object({
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    phone: z.string().regex(/\([2-9][0-9]{2}\) [0-9]{3}-[0-9]{4}/gi),
    email: z.string().trim().email().optional().or(z.literal('')),
})

export const GetStartedPage = () => {
    const {
        register,
        watch,
        setError,
        clearErrors,
        formState: { errors, isValid },
    } = useForm({
        resolver: zodResolver(getStartedFormSchema),
        mode: 'onChange',
        shouldFocusError: false,
    })
    const { gatheredInformations, setGatheredInformations } = useContext(FormDataContext)
    const { navigateWithLang } = useNavigateWithLang()
    const { t } = useTranslation('forms')

    const goToNextPage = () => {
        if (!isValid) return
        const watchedInfo = watch()

        setGatheredInformations({
            ...gatheredInformations,
            firstName: watchedInfo.firstName,
            lastName: watchedInfo.lastName,
            phoneNumber: watchedInfo.phone,
            emailAddress: watchedInfo.email,
        })
        navigateWithLang('/covid-otc/health-plan-details')
    }

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[480px] flex-col'
        >
            <Typography type='h3'>{t('forms:get_started.title')}</Typography>
            <Typography type='paragraph'>{t('forms:get_started.description')}</Typography>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className='mb-3 flex flex-col gap-y-4 gap-x-4 sm:flex-row'>
                    <Input
                        label={t('forms:get_started.labels.first_name')}
                        id='firstName'
                        type='text'
                        placeholder={t('forms:get_started.labels.first_name')}
                        register={register}
                        errors={errors}
                        watch={watch}
                        value={gatheredInformations.firstName}
                        className='capitalize'
                    />
                    <Input
                        label={t('forms:get_started.labels.last_name')}
                        id='lastName'
                        type='text'
                        placeholder={t('forms:get_started.labels.last_name')}
                        register={register}
                        errors={errors}
                        watch={watch}
                        value={gatheredInformations.lastName}
                        className='capitalize'
                    />
                </div>
                <div className='mb-3'>
                    <Input
                        label={t('forms:get_started.labels.phone')}
                        id='phone'
                        type='text'
                        placeholder='(305)  000-0000'
                        register={register}
                        errors={errors}
                        watch={watch}
                        setError={setError}
                        clearErrors={clearErrors}
                        mask='\([2-9][0-9]{2}\) [0-9]{3}\-[0-9]{4}'
                        value={gatheredInformations.phoneNumber}
                    />
                </div>
                <Input
                    label={t('forms:get_started.labels.email')}
                    id='email'
                    type='email'
                    placeholder='email'
                    register={register}
                    errors={errors}
                    watch={watch}
                    value={gatheredInformations.emailAddress}
                    optionalPlaceholder={true}
                    className='lowercase'
                />

                <div className='mt-9'>
                    <NextButton isValid={isValid} onClick={goToNextPage} title={t('forms:get_started.continue_btn')} />
                </div>
            </form>
        </motion.div>
    )
}
