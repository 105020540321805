import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom'
import { UUIDContext } from './modules/draft_landing/uuid-context'
import { CheckoutPage } from './modules/claim-kits/forms/checkout'
import { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { useNavigateWithLang } from './hooks/useNavigateWithLang'
import { Blocks } from './pages/blocks'
import { FAQPage } from './modules/FAQ/faq-page'
import { ShippingAddressConfirm } from './modules/claim-kits/forms/shipping-address-confirm'
import { MaintenancePage } from './pages/maintenance'
import { LandingPage } from './modules/landing/landing-page'
import { ContactPage } from './modules/claim-kits/forms/contact'
import { Unsubscribe } from './modules/claim-kits/forms/unsubscribe'
import Cookies from 'js-cookie'
import { PrivacyPage } from './modules/legal/privacy'
import { TermsPage } from './modules/legal/terms'
import { HeathlPlanPage } from './modules/claim-kits/forms/health-plan'
import { GetStartedPage } from './modules/claim-kits/forms/get-started'
import { InsuranceProviderPage } from './modules/claim-kits/forms/insurance-provider'
import { ShippingAddressPage } from './modules/claim-kits/forms/shipping-address'
// import { SubscribeMonthlyPage } from './modules/claim-kits/forms/subscribe-monthly'
import { ConsentFormsPage } from './modules/claim-kits/forms/consent-forms'
import { RequestConfirmationPage } from './modules/claim-kits/req-confirmation'
import { ReqErrorPage } from './modules/claim-kits/req-error'
import { ClaimKitsPage } from './modules/claim-kits/claim-kits-page'
import { HealthPlanDetailsPage } from './modules/claim-kits/forms/health-plan-details'
import { CompanyMessage } from './modules/company-message'

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })

export const SUPPORTED_LANGUAGES = ['en', 'es']

export const App = () => {
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()
    const { getUrlWithLang, getLanguageFromURL } = useNavigateWithLang()
    const [source, setSource] = useState<string | null>(null)
    const clickID = searchParams.get('click_id')
    const maintenance = false

    useEffect(() => {
        const sourceParam = searchParams.get('source')
        if (sourceParam) {
            setSource(sourceParam)
        }
    }, [searchParams])

    useEffect(() => {
        if (clickID) {
            Cookies.set('click_id', clickID)
        }
    }, [clickID])

    useEffect(() => {
        const lang = getLanguageFromURL(pathname)
        if (lang) {
            i18n.changeLanguage(lang)
        }
    }, [getLanguageFromURL, pathname])

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Helmet>
                <meta
                    name='description'
                    content='Claim your over-the-counter COVID-19 tests from the comfort and safety of your home.'
                    data-react-helmet='true'
                />
                <title>YourKits.com • $0 OTC COVID-19 Tests, Delivered</title>
            </Helmet>

            {!maintenance ? (
                <Routes>
                    <Route path='/blocks' element={<Blocks />} />
                    <Route path='/faq' element={<FAQPage />} />
                    <Route path='/company-message' element={<CompanyMessage />} />
                    <Route path={getUrlWithLang(`/`)} element={<LandingPage />} />
                    <Route path={getUrlWithLang(`/covid-otc`)} element={<ClaimKitsPage />}>
                        <Route index element={<HeathlPlanPage />} />
                        <Route path='health-plan' element={<HeathlPlanPage />} />
                        <Route path='get-started' element={<GetStartedPage />} />
                        <Route path='insurance-provider' element={<InsuranceProviderPage />} />
                        <Route path='health-plan-details' element={<HealthPlanDetailsPage />} />
                        <Route path='shipping-address' element={<ShippingAddressPage />} />
                        <Route path='shipping-address-confirm' element={<ShippingAddressConfirm />} />
                        {/* <Route path='subscribe-monthly' element={<SubscribeMonthlyPage />} /> */}
                        <Route path='consent' element={<ConsentFormsPage />} />
                        <Route path='contact' element={<ContactPage />} />
                        <Route path='unsubscribe' element={<Unsubscribe />} />
                        <Route
                            path='checkout'
                            element={
                                <UUIDContext.Provider value={{ source }}>
                                    <CheckoutPage />
                                </UUIDContext.Provider>
                            }
                        />
                        <Route path='req-confirmation' element={<RequestConfirmationPage />} />
                        <Route path='req-err' element={<ReqErrorPage />} />
                    </Route>
                    <Route path='/legal'>
                        <Route path='' element={<PrivacyPage />} />
                        <Route path='privacy' element={<PrivacyPage />} />
                        <Route path='terms' element={<TermsPage />} />
                    </Route>
                    <Route path='*' element={<p>404</p>} />
                </Routes>
            ) : (
                <Routes>
                    <Route path='*' element={<MaintenancePage />} />
                </Routes>
            )}
        </Suspense>
    )
}
