import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Footer } from './shared/footer';

export const PrivacyPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Privacy</title>
      </Helmet>
      <div className='px-4 bg-white'>
        <div className='flex items-center justify-between max-w-3xl mx-auto h-[91px]'>
          <Link to='/' className='text-3xl' data-barba-prevent=''>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              width={1000}
              zoomAndPan='magnify'
              viewBox='0 0 750 374.999991'
              height={500}
              preserveAspectRatio='xMidYMid meet'
              version='1.0'
              className='h-12 w-28'
            >
              <defs>
                <g />
              </defs>
              <g fill='#3E4D2C' fillOpacity={1}>
                <g transform='translate(15.083422, 310.801587)'>
                  <g>
                    <path d='M 8.417969 -221.597656 C 17.511719 -220.925781 30.648438 -226.3125 30.648438 -215.535156 L 30.648438 -33.003906 C 30.648438 -26.941406 27.277344 -21.21875 21.554688 -18.523438 C 19.195312 -17.175781 16.839844 -15.828125 14.816406 -14.480469 C 9.09375 -10.441406 12.460938 0 19.195312 0 L 115.515625 0 C 122.585938 0 125.953125 -10.441406 119.890625 -14.480469 C 117.871094 -15.828125 116.1875 -17.175781 114.167969 -18.1875 C 109.117188 -21.554688 105.410156 -27.277344 105.410156 -33.003906 L 105.410156 -79.816406 C 118.882812 -81.164062 129.320312 -77.796875 138.078125 -51.863281 C 143.804688 -35.363281 147.84375 -24.585938 150.539062 -16.164062 C 154.242188 -6.398438 162.664062 0 172.765625 0 L 238.773438 0 C 245.847656 0 248.878906 -10.441406 243.152344 -14.480469 C 241.132812 -15.828125 238.4375 -16.164062 235.40625 -16.503906 C 230.019531 -16.839844 224.628906 -21.554688 222.609375 -27.953125 L 213.515625 -54.222656 C 202.066406 -88.234375 184.554688 -101.371094 154.242188 -101.371094 C 149.191406 -101.371094 143.804688 -101.03125 137.742188 -100.359375 C 155.253906 -112.820312 164.007812 -128.984375 178.152344 -128.984375 C 193.308594 -128.648438 191.625 -108.441406 217.21875 -106.085938 C 230.691406 -104.738281 242.140625 -115.515625 242.476562 -130.667969 C 243.488281 -154.242188 220.25 -167.039062 198.359375 -166.03125 C 148.519531 -164.347656 148.519531 -121.914062 105.410156 -105.746094 L 105.410156 -217.21875 C 105.410156 -247.53125 94.972656 -251.234375 68.027344 -251.234375 C 46.476562 -251.234375 20.878906 -245.847656 6.0625 -237.425781 C -1.011719 -233.386719 1.011719 -221.933594 8.417969 -221.597656 Z M 8.417969 -221.597656 ' />
                  </g>
                </g>
              </g>
              <g fill='#3E4D2C' fillOpacity={1}>
                <g transform='translate(263.543677, 310.801587)'>
                  <g>
                    <path d='M 8.082031 -128.648438 C 12.460938 -128.648438 18.1875 -129.660156 22.5625 -129.660156 C 27.277344 -129.660156 30.648438 -128.3125 30.648438 -122.921875 L 30.648438 -33.003906 C 30.648438 -26.941406 26.941406 -21.21875 21.554688 -18.523438 C 18.859375 -17.175781 16.503906 -15.828125 14.816406 -14.480469 C 9.09375 -10.441406 12.125 0 18.859375 0 L 116.523438 0 C 123.261719 0 126.628906 -10.441406 120.902344 -14.480469 C 118.882812 -15.828125 116.859375 -17.175781 115.175781 -18.1875 C 109.789062 -21.554688 106.421875 -27.277344 106.421875 -33.003906 L 106.421875 -124.605469 C 106.421875 -154.578125 95.644531 -158.621094 69.039062 -158.621094 C 47.484375 -158.621094 20.878906 -153.234375 5.726562 -144.476562 C -1.011719 -140.773438 0.671875 -129.320312 8.082031 -128.648438 Z M 17.511719 -220.925781 C 17.511719 -198.023438 34.351562 -184.554688 62.976562 -184.554688 C 91.601562 -184.554688 108.105469 -198.023438 108.105469 -220.925781 C 108.105469 -243.488281 91.601562 -256.621094 62.976562 -256.621094 C 34.351562 -256.621094 17.511719 -243.488281 17.511719 -220.925781 Z M 17.511719 -220.925781 ' />
                  </g>
                </g>
              </g>
              <g fill='#3E4D2C' fillOpacity={1}>
                <g transform='translate(391.813847, 310.801587)'>
                  <g>
                    <path d='M 18.859375 -139.761719 L 37.71875 -139.761719 L 37.71875 -46.8125 C 37.71875 -12.796875 58.261719 4.714844 96.992188 5.386719 C 148.519531 6.0625 161.988281 -14.816406 157.949219 -21.890625 C 156.9375 -23.574219 155.253906 -23.910156 152.894531 -23.910156 C 146.496094 -23.910156 135.046875 -18.523438 126.289062 -18.523438 C 119.21875 -18.523438 113.832031 -21.890625 113.832031 -34.6875 L 113.832031 -139.761719 L 143.804688 -139.761719 C 148.855469 -139.761719 152.222656 -143.128906 152.222656 -148.179688 C 152.222656 -153.570312 148.855469 -156.9375 143.804688 -156.9375 L 113.832031 -156.9375 L 113.832031 -227.324219 C 113.832031 -233.386719 109.789062 -236.753906 103.726562 -235.40625 L 67.691406 -227.996094 C 61.964844 -226.648438 57.589844 -221.933594 57.25 -215.535156 C 54.222656 -187.585938 42.097656 -157.609375 17.175781 -156.9375 L 17.511719 -156.601562 C 13.132812 -156.265625 10.441406 -152.894531 10.441406 -148.519531 L 10.441406 -148.179688 C 10.441406 -143.128906 13.808594 -139.761719 18.859375 -139.761719 Z M 18.859375 -139.761719 ' />
                  </g>
                </g>
              </g>
              <g fill='#3E4D2C' fillOpacity={1}>
                <g transform='translate(552.740636, 310.801587)'>
                  <g>
                    <path d='M 6.398438 -30.308594 C 18.859375 -1.011719 72.40625 5.386719 93.625 5.386719 C 153.570312 5.386719 184.554688 -15.828125 182.53125 -53.210938 C 179.839844 -109.789062 69.375 -102.714844 71.058594 -133.363281 C 71.394531 -142.121094 81.5 -146.835938 93.960938 -145.824219 C 126.289062 -143.128906 123.933594 -112.484375 150.539062 -108.777344 C 163.335938 -107.09375 174.113281 -115.515625 171.753906 -128.3125 C 168.722656 -147.507812 140.773438 -160.980469 93.285156 -163 C 40.75 -165.355469 7.074219 -144.476562 7.410156 -114.167969 C 7.746094 -60.28125 117.199219 -50.515625 117.199219 -25.257812 C 117.199219 -15.492188 109.453125 -9.429688 91.941406 -12.125 C 47.820312 -18.859375 58.261719 -56.914062 28.625 -60.957031 C 9.09375 -63.652344 -1.011719 -47.148438 6.398438 -30.308594 Z M 6.398438 -30.308594 ' />
                  </g>
                </g>
              </g>
            </svg>
          </Link>
        </div>
      </div>
      <div className='mx-auto bg-[#FFF9F2] py-12'>
        <div className='max-w-3xl px-4 mx-auto'>
          <h2 className='text-[25.5px] font-semibold mb-4'>Privacy Policy</h2>
          <p>For any additional support please contact</p>
          <p>privacy@yourkits.com</p>
        </div>
      </div>
      <section className='pt-9'>
        <div className='max-w-3xl px-4 mx-auto'>
          <h4 className='text-lg'>Privacy Policy</h4>
          <p className='mt-9'>
            Your privacy is critical to us. Likewise, we have built up this Policy with the end goal you should see how we gather, utilize, impart and
            reveal and make utilization of individual data. The following blueprints our privacy policy.
          </p>
          <p className='mt-9'>
            We will gather and utilize individual data singularly with the target of satisfying those reasons indicated by us and for other good
            purposes, unless we get the assent of the individual concerned or as required by law.
          </p>
          <p className='mt-9'>We will just hold individual data the length of essential for the satisfaction of those reasons.</p>
          <p className='mt-9'>
            We will gather individual data by legal and reasonable means and, where fitting, with the information or assent of the individual
            concerned.
          </p>
          <p className='mt-9'>
            Personal information ought to be important to the reasons for which it is to be utilized, and, to the degree essential for those reasons,
            ought to be exact, finished, and updated.
          </p>
          <p className='mt-9'>
            We will protect individual data by security shields against misfortune or burglary, and also unapproved access, divulgence, duplicating,
            use or alteration.
          </p>
          <p className='mt-9'>
            We will promptly provide customers with access to our policies and procedures for the administration of individual data.
          </p>
          <p className='mt-9'>
            We are focused on leading our business as per these standards with a specific end goal to guarantee that the privacy of individual data is
            secure and maintained.
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
};
