import React, { SyntheticEvent, useContext } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { Input } from '../../../components/input'
import { Typography } from '../../../components/typography'
import { formStepsTransitionVariants } from '../../../constants/form-steps-transition-variants'
import { useNavigateWithLang } from '../../../hooks/useNavigateWithLang'
import { FormDataContext } from '../context/form-data-context'
import { NextButton } from '../shared/next-button'

const HealthPlanDetailsFormSchema = z.object({
    medicareNumber: z
        .string()
        .regex(/[1-9][^SLOIBZ0-9][^SLOIBZ][0-9]-[^SLOIBZ0-9][^SLOIBZ][0-9]-[^SLOIBZ0-9]{2}[0-9]{2}/gi),
})

export const HealthPlanDetailsPage = () => {
    const {
        register,
        watch,
        clearErrors,
        setError,
        formState: { errors, isValid },
    } = useForm({
        resolver: zodResolver(HealthPlanDetailsFormSchema),
        mode: 'onChange',
    })
    const { gatheredInformations, setGatheredInformations } = useContext(FormDataContext)
    const { t } = useTranslation('forms')
    const { navigateWithLang } = useNavigateWithLang()

    const goToNextPage = (e: SyntheticEvent) => {
        e.preventDefault()

        if (!isValid) {
            return
        }

        const watchedInfo = watch()

        setGatheredInformations({
            ...gatheredInformations,
            medicareNumber: watchedInfo.medicareNumber,
        })

        navigateWithLang('/covid-otc/shipping-address')
    }

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[480px] flex-col'
        >
            <Typography type='h6'>{t('forms:health_plan_details.subtitle')}</Typography>
            <Typography type='h3'>{t('forms:health_plan_details.title')}</Typography>
            <Typography type='paragraph'>{t('forms:health_plan_details.description')}</Typography>

            <div className='mb-4 flex justify-center'>
                <img src={require('../../../assets/medicare-card.png')} alt='' className='w-8/12' />
            </div>
            <form onSubmit={goToNextPage}>
                <Input
                    type='text'
                    label={t('forms:health_plan_details.medicare_number_label')}
                    id='medicareNumber'
                    placeholder={t('forms:health_plan_details.medicare_number_label')}
                    register={register}
                    errors={errors}
                    watch={watch}
                    setError={setError}
                    clearErrors={clearErrors}
                    mask='([1-9][^SLOIBZ0-9][^SLOIBZ][0-9]-[^SLOIBZ0-9][^SLOIBZ][0-9]-[^SLOIBZ0-9]{2}[0-9]{2}'
                    value={gatheredInformations.medicareNumber}
                />
            </form>

            <div className='mt-6' />
            <NextButton onClick={goToNextPage} isValid={isValid} title={t('forms:common.continue_btn')} />
        </motion.div>
    )
}
