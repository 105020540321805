import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './app'
import reportWebVitals from './reportWebVitals'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import './styles/index.css'

const tagManagerArgs = {
    gtmId: `GTM-${process.env.REACT_APP_GOOGLE_MANAGER_GTM}`,
}

TagManager.initialize(tagManagerArgs)

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_DEV as string
} else {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_PROD as string
}
axios.defaults.headers.common['X-Bounce-Token'] = process.env.REACT_APP_BOUNCE_TOKEN as string

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
