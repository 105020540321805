import { zodResolver } from '@hookform/resolvers/zod'
import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { Checkbox } from '../../../components/checkbox'
import { Typography } from '../../../components/typography'
import { formStepsTransitionVariants } from '../../../constants/form-steps-transition-variants'
import { useNavigateWithLang } from '../../../hooks/useNavigateWithLang'
import { FormDataContext } from '../context/form-data-context'
import { NextButton } from '../shared/next-button'

const profileFormSchema = z.object({
    medicareRecipient: z.string(),
})

export const HeathlPlanPage = () => {
    const {
        register,
        watch,
        formState: { isValid },
    } = useForm({ resolver: zodResolver(profileFormSchema), mode: 'onChange' })
    const { gatheredInformations, setGatheredInformations } = useContext(FormDataContext)
    const { t } = useTranslation('forms')
    const { navigateWithLang } = useNavigateWithLang()

    const goToNextPage = () => {
        const watchedInfo = watch()

        setGatheredInformations({
            ...gatheredInformations,
            isMedicareRecipient: watchedInfo.medicareRecipient,
        })

        if (watchedInfo.medicareRecipient === 'no') {
            navigateWithLang('/covid-otc/req-err')
            return
        }

        navigateWithLang('/covid-otc/get-started')

        // if (watchedInfo.medicareRecipient === "yes") {
        //     navigate("/covid-otc/health-plan-details");
        // } else {
        //     navigate("/covid-otc/insurance-provider");
        // }
    }

    return (
        <motion.div
            variants={formStepsTransitionVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='mx-auto flex max-w-[480px] flex-col'
        >
            <Typography type='h6'>{t('forms:health_plan.subtitle')}</Typography>
            <Typography type='h3'>{t('forms:health_plan.title')}</Typography>
            <Typography type='paragraph'>{t('forms:health_plan.description')}</Typography>
            <div className='flex flex-col gap-y-3'>
                <Checkbox
                    name='medicareRecipient'
                    label={t('forms:health_plan.yes')}
                    register={register}
                    defaultChecked={gatheredInformations.isMedicareRecipient}
                />
                <Checkbox
                    name='medicareRecipient'
                    label={t('forms:health_plan.no')}
                    register={register}
                    defaultChecked={gatheredInformations.isMedicareRecipient}
                />
            </div>
            <div className='mt-9' />
            <NextButton onClick={goToNextPage} isValid={isValid} title={t('forms:common.continue_btn')} />
        </motion.div>
    )
}
