import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FAQToggleQuestion } from './shared/faq-toggle-question'
import DownloadIcon from '../../assets/icons/download.svg'
import CheckMarkIcon from '../../assets/icons/check_fill.svg'
import { useNavigateWithLang } from '../../hooks/useNavigateWithLang'
import { Header } from './shared/header'
import { useState } from 'react'
import { motion } from 'framer-motion'
import CircleSvg from '../../assets/kits_graphics_circle.svg'
import PlayIcon from '../../assets/icons/play.svg'
import { Dialog } from '@headlessui/react'
import CMSLogo from '../../assets/kits_lp_cms_logo.png'
import { KitsLogo as KitsLogoIcon } from './shared/header'
import { Footer } from './shared/footer'

export const LandingPage = () => {
    const { t } = useTranslation('landing')
    const { getUrlWithLang } = useNavigateWithLang()
    const [allQuestions, setAllQuestions] = useState(false)
    let [isOpen, setIsOpen] = useState(true)

    return (
        <main>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='relative z-50'>
                <div className='fixed inset-0 flex items-center justify-center bg-[#2B342C] bg-opacity-70 p-4'>
                    <Dialog.Panel className='flex w-full max-w-[600px] flex-col items-center rounded-[30px] border-2 border-[#F1F5F9] bg-[#2B342C] py-9 px-4 text-center text-white md:px-16'>
                        <div className='mb-[30px]'>
                            <KitsLogoIcon />
                        </div>
                        <Dialog.Title className='mb-2 text-[20pt] md:mb-5 md:text-[38pt]'>
                            Important Updates
                        </Dialog.Title>
                        <Dialog.Description className='mb-2 text-[13pt] md:mb-[27px] md:text-[15pt]'>
                            <span className='underline'>ATTENTION:</span> May 11, 2023, marks the end of the federal
                            COVID-19 PHE declaration. As of May 12, 2023, Medicare will no longer cover or pay for
                            Over-the-Counter COVID-19 tests.
                        </Dialog.Description>
                        <div className='w-full px-4'>
                            <div className='mx-auto flex w-full justify-center gap-x-3 rounded-[25px] border-2 border-white pb-[15px] pt-[13px] text-[11pt] text-white'>
                                <img className='h-9' src={CMSLogo} alt='' />
                                <div>
                                    <p className='text-left text-[#FFF9F2]'>Medicare Part B Coverage</p>
                                    <a
                                        href='https://www.cms.gov/files/document/covid-over-counter-test-coverage.pdf?utm_source=YourKits.com'
                                        className='text-sm text-[#D6D3D1] underline'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        See Announcement from CMS
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='mx-auto my-[40px] h-[2px] w-1/2 bg-[#A8A29E]' />
                        <p className='px-[5px] text-[13pt]'>
                            If you're an existing patient, our support team is still available to assist with your
                            requests.
                        </p>
                        <div className='w-full px-3'>
                            <a
                                href='mailto:support@yourkits.com'
                                className='mt-[30px] inline-block w-full rounded-full bg-[#E9E0D3] py-4 text-[13pt] text-black'
                            >
                                Contact support
                            </a>
                        </div>
                        <button className='mt-6 text-[12pt] text-[#D6D3D1] underline' onClick={() => setIsOpen(false)}>
                            Continue to website
                        </button>
                    </Dialog.Panel>
                </div>
            </Dialog>

            <Header />
            <section className='container mx-auto flex max-w-5xl flex-col items-center gap-y-10 py-[60px]'>
                <div className='flex flex-col items-center gap-y-2'>
                    <h4 className='text-center text-[10pt] font-medium text-red-400'>how it works</h4>
                    <h2 className='text-center text-[25pt] font-semibold'>Getting started is simple</h2>
                    <p className='max-w-[420px] text-center text-[13pt]'>
                        You can request your kits today in just 3 easy steps right from your phone.
                    </p>
                </div>
                <div className='flex flex-col items-center gap-y-8 gap-x-5 md:flex-row lg:gap-x-12'>
                    <div className='flex flex-row items-center justify-center gap-y-2 gap-x-3 text-center md:flex-col'>
                        <img
                            className='aspect-square w-1/4 rounded-2xl border object-cover sm:w-[7rem] md:w-[16.6rem]'
                            src={require('../../assets/kits_lp_1.jpg')}
                            alt=''
                        />

                        <div className='flex w-[230px] flex-col gap-y-1 text-start md:text-center'>
                            <h3 className='text-[14pt] font-medium md:mt-2'>1. Submit your request</h3>
                            <p className='text-[12pt]'>Answer a few basic questions to confirm your eligibility.</p>
                        </div>
                    </div>

                    <div className='flex flex-row items-center justify-center gap-y-2 gap-x-3 text-center md:flex-col'>
                        <img
                            className='aspect-square w-1/4 rounded-2xl border object-cover sm:w-[7rem] md:w-[16.6rem]'
                            src={require('../../assets/how_it_works_2.webp')}
                            alt=''
                        />
                        <div className='flex w-[230px] flex-col gap-y-1 text-start md:text-center'>
                            <h3 className='text-[14pt] font-medium md:mt-2'>2. Receive your kits</h3>
                            <p className='text-[12pt]'>Your kits will be prepared and shipped for free.</p>
                        </div>
                    </div>

                    <div className='flex flex-row items-center justify-center gap-y-2 gap-x-3 text-center md:flex-col'>
                        <img
                            className='aspect-square w-1/4 rounded-2xl border object-cover sm:w-[7rem] md:w-[16.6rem]'
                            src={require('../../assets/how_it_works_3.jpg')}
                            alt=''
                        />
                        <div className='flex w-[230px] flex-col gap-y-1 text-start md:text-center'>
                            <h3 className='text-[14pt] font-medium md:mt-2'>3. Test yourself</h3>
                            <p className='text-[12pt]'>Get results in at quick as 15 minutes from home.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='relative overflow-hidden bg-[#FFF9F2]'>
                <div className='container relative mx-auto flex max-w-5xl flex-col items-center py-16 pb-56 md:pb-20'>
                    <h2 className='mb-4 text-center text-[25pt] font-[600]'>
                        {t('claim_kit.title', { ns: 'landing' })}
                        <span className='relative ml-2 inline-block'>
                            <img
                                className='absolute z-10 h-full w-full'
                                src={require('../../assets/kits-circle.png')}
                                alt=''
                            />
                            <span className='relative z-20'>pay $0</span>
                        </span>
                    </h2>
                    <p className='max-w-[380px] text-center text-[13pt]'>
                        {t('claim_kit.description', { ns: 'landing' })}
                    </p>
                    <Link to={getUrlWithLang('/covid-otc/health-plan')}>
                        <span className='mt-6 block rounded-[18px] border-2 border-[#D5D3D1] px-9 py-[12px] transition-all hover:bg-slate-50'>
                            See if you qualify
                        </span>
                    </Link>
                    <img
                        className='absolute bottom-0 left-16 -mb-8 h-[250px] w-auto md:h-5/6'
                        src={require('../../assets/kits_lp_hand_card.png')}
                        alt=''
                    />
                    <img
                        className='absolute bottom-0 right-16 -mb-8 h-[250px] w-auto md:h-5/6'
                        src={require('../../assets/kits_lp_hands_test.png')}
                        alt=''
                    />
                </div>
            </section>

            <section className='container mx-auto flex max-w-5xl flex-col items-center gap-y-10 py-[60px] md:py-[100px]'>
                <div className='flex flex-col items-center gap-y-2'>
                    <h4 className='text-center text-[10pt] font-medium text-red-400'>why us</h4>
                    <h2 className='text-center text-[25pt] font-semibold'>We do things differently.</h2>
                    <p className='max-w-[420px] text-center text-[13pt]'>
                        Not sure if you're eligible? No worries, we're here to make sure you don't have to come out of
                        pocket.
                    </p>
                </div>

                <div className='flex w-full flex-col justify-center gap-y-6 md:flex-row'>
                    <div className='flex w-full flex-col items-center justify-between gap-y-4 rounded-3xl border bg-[#FFF9F2] px-6 py-6 md:w-[38%]'>
                        <KitsLogo />
                        <ul className='flex w-full flex-col items-start gap-y-2 px-2'>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_1.webp')} alt='' className='-mt-1 w-5' />
                                </div>
                                Order online or by phone
                            </li>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_2.webp')} alt='' className='-mt-1 w-5' />
                                </div>
                                Free expedited delivery
                            </li>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_3.webp')} alt='' className='-mt-1 w-4' />
                                </div>
                                Billed directly with Medicare
                            </li>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_4.webp')} alt='' className='-mt-1 w-3' />
                                </div>
                                HIPAA compliant
                            </li>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_5.webp')} alt='' className='-mt-1 w-5' />
                                </div>
                                Telephone support
                            </li>
                        </ul>
                        <Link to={'/covid-otc/health-plan'} className='w-full'>
                            <span className='mx-auto flex w-full max-w-[540px] items-center justify-center rounded-md bg-[#F26E40] py-[12px] text-[13pt] text-white transition-all hover:bg-[#db6f47]'>
                                {t('otc_test_kit.test_kit_card.order_label', {
                                    ns: 'landing',
                                })}
                            </span>
                        </Link>
                    </div>

                    <div className='mx-auto flex w-[14%] items-center justify-center'>
                        <img
                            className='w-12/12 mt-0 md:-mt-16 md:w-5/12'
                            src={require('../../assets/versus.jpg')}
                            alt=''
                        />
                    </div>

                    <div className='flex w-full flex-col items-center justify-between gap-y-4 rounded-3xl border bg-white px-6 py-6 md:w-[38%]'>
                        <h3 className='text-[17pt] text-[#545454]'>Other Providers</h3>
                        <ul className='flex w-full flex-col items-start gap-y-2 px-2'>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_6.webp')} alt='' className='-mt-1 w-4' />
                                </div>
                                Call to confirm participation
                            </li>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_7.webp')} alt='' className='-mt-1 w-4' />
                                </div>
                                Commute and request
                            </li>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_8.webp')} alt='' className='-mt-1 w-5' />
                                </div>
                                Pay out of pocket
                            </li>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_9.webp')} alt='' className='-mt-1 w-4' />
                                </div>
                                Submit receipt to Medicare
                            </li>
                            <li className='flex items-center gap-x-3'>
                                <div className='flex w-5 justify-center'>
                                    <img src={require('../../assets/why_us_10.webp')} alt='' className='-mt-1 w-4' />
                                </div>
                                Wait for reimbursement
                            </li>
                        </ul>
                        <a href='https://www.medicare.gov/covid-19-tests-participating-pharmacies' className='w-full'>
                            <span className='mx-auto flex w-full max-w-[540px] items-center justify-center rounded-md border border-[#D5D3D1] bg-[#F5F5F4] py-[12px] text-[13pt] text-[#545454] transition-all'>
                                Participating Pharmacies
                            </span>
                        </a>
                    </div>
                </div>
            </section>

            <section className='bg-[#FFF9F2] py-[60px] px-4 md:py-20'>
                <div className='flex flex-col items-center gap-y-2'>
                    <h4 className='text-center text-[10pt] font-medium text-red-400'>test kit</h4>
                    <h2 className='max-w-[430px] text-center text-[25pt] font-semibold'>
                        An in-home test designed to get accurate results.
                    </h2>
                    <p className='mt-4 max-w-[430px] text-center text-[13pt]'>
                        InBios Detect™ COVID-19 Rapid Self-Test Kit is the easy and non-invasive way to detect
                        SARS-CoV-2 nucleocapsid protein antigen.
                    </p>
                    <p className='mt-4 max-w-[430px] text-center text-[13pt]'>
                        It has a highly-absorbent swab that is individually sealed to help prevent contamination so you
                        can view the results of your in-home test with confidence.
                    </p>
                </div>
                <div className='mx-auto mt-6 flex flex-col rounded-2xl border bg-white pt-8 pb-6 sm:w-[550px]'>
                    <div className='flex flex-col items-center gap-y-6 gap-x-6 px-8 md:flex-row'>
                        <div className='w-48'>
                            <img
                                className='h-full w-full object-contain'
                                src={require('../../assets/inbios-kit.jpg')}
                                alt=''
                            />
                        </div>
                        <div>
                            <h3 className='mb-2 text-[16.5pt] font-semibold'>
                                {t('otc_test_kit.test_kit_card.heading', {
                                    ns: 'landing',
                                })}
                            </h3>
                            <p className='mb-5 text-[12pt]'>
                                {t('otc_test_kit.test_kit_card.sub_heading', {
                                    ns: 'landing',
                                })}
                            </p>
                            <ul className='flex flex-col gap-y-2'>
                                <li className='flex items-center gap-x-2'>
                                    <img src={CheckMarkIcon} className='h-4 w-4' alt='' />
                                    <p className='text-[10pt]'>Covered by Medicare Part-B</p>
                                </li>
                                <li className='flex  gap-x-2'>
                                    <img src={CheckMarkIcon} className='h-4 w-4' alt='' />
                                    <span className='text-[10pt]'>
                                        <p>FDA Emergency Use Authorized</p>
                                        <a
                                            href='https://inbios.com/wp-content/uploads/2022/01/EUA210619.S001.InBiosOTC-EUA-LOA-reissue-01-25-2022-FINAL.pdf'
                                            target='_blank'
                                            className='underline'
                                            rel='noreferrer'
                                        >
                                            EUA: EUA210619
                                        </a>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='mt-4 mb-6 h-0.5 bg-[#F0F5F9]' />
                    <div className='mb-2 flex gap-x-5 px-2 md:px-6'>
                        <a
                            href='https://www.youtube.com/watch?v=TlGZUJZtgKo&ab_channel=InBiosInternational'
                            className='flex h-14 flex-grow items-center justify-center gap-x-4 rounded-lg bg-[#EDEDED] text-lg capitalize text-black'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t('otc_test_kit.test_kit_card.watch_video_cta', {
                                ns: 'landing',
                            })}
                            <img src={PlayIcon} className='h-5 w-5' alt='' />
                        </a>
                        <a
                            href='https://inbios.com/wp-content/uploads/2022/01/EUA210619.S001.InBiosOTC-EUA-LOA-reissue-01-25-2022-FINAL.pdf'
                            className='flex h-14 flex-grow items-center justify-center gap-x-4 rounded-lg bg-[#EDEDED] text-lg capitalize text-black'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t('otc_test_kit.test_kit_card.instructions_cta', {
                                ns: 'landing',
                            })}
                            <img src={DownloadIcon} className='h-5 w-5' alt='' />
                        </a>
                    </div>
                </div>
                <Link to={getUrlWithLang('/covid-otc/health-plan')} className='w-full'>
                    <span className='mx-auto mt-8 flex w-full max-w-[460px] items-center justify-center rounded-lg bg-[#F26E40] py-[12px] text-[16.5pt] text-white transition-all hover:bg-[#db6f47]'>
                        {t('otc_test_kit.test_kit_card.order_label', {
                            ns: 'landing',
                        })}
                    </span>
                </Link>
            </section>

            <motion.section
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                className='overflow-hidden bg-[#F5F5F4]'
            >
                <div className='container mx-auto flex max-w-5xl flex-col py-[55px] px-4 lg:px-32'>
                    <div className='relative z-30 flex flex-col items-center gap-y-2'>
                        <h4 className='text-center text-[10pt] font-medium text-red-400'>FAQ</h4>
                        <h2 className='text-center text-[25pt] font-semibold'>Common questions</h2>
                        <p className='max-w-[550px] text-center text-[13pt]'>
                            Here's some of the most commonly asked questions. If we couldn't answer all your questions,
                            please feel free to{' '}
                            <a className='underline' href='mailto:support@yourkits.com'>
                                contact us.
                            </a>
                        </p>
                    </div>

                    <div className='relative mx-auto mt-6 flex max-w-full flex-col items-center justify-center gap-y-2 sm:px-4 md:max-w-xl lg:max-w-5xl'>
                        <img
                            className='absolute -top-10 -left-16 z-10 h-36'
                            src={require('../../assets/faq_1.webp')}
                            alt=''
                        />
                        <img
                            className='absolute -bottom-3 -right-20 z-10 h-32 -rotate-12 transform'
                            src={require('../../assets/faq_2.webp')}
                            alt=''
                        />
                        <div className='relative z-20 mx-auto rounded-3xl'>
                            <div>
                                <FAQToggleQuestion
                                    className='w-9/12 md:w-[450px]'
                                    initialVisible={false}
                                    headerTitle='Am I eligible?'
                                    bodyContent={
                                        <span>
                                            Medicare will cover these tests if you have Medicare Part B, including those
                                            enrolled in a Medicare Advantage plan. Medicare won't cover over-the-counter
                                            COVID-19 tests if you only have Medicare Part A (Hospital Insurance)
                                            coverage.
                                            <Link to='/covid-otc/health-plan'>
                                                <span className='underline'>
                                                    Click here to see if you’re eligible to receive your free tests.{' '}
                                                    {'->'}
                                                </span>
                                            </Link>
                                        </span>
                                    }
                                />

                                <FAQToggleQuestion
                                    className='w-9/12 md:w-[450px]'
                                    headerTitle='Can I request tests without Medicare Part B coverage?'
                                    bodyContent={
                                        <span>
                                            Patients who only have Medicare Part A can get free OTC COVID-19 tests
                                            through other government-led programs, like{' '}
                                            <a href='https://www.covid.gov/tests' className='underline'>
                                                covidtests.gov
                                            </a>
                                            . Every home in the U.S. is eligible to order two sets of four at-home
                                            COVID-19 tests for free.
                                        </span>
                                    }
                                />
                            </div>

                            <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                                    allQuestions ? `h-auto` : 'h-0'
                                }`}
                            >
                                <FAQToggleQuestion
                                    className='w-9/12 md:w-[450px]'
                                    headerTitle='Do I have to pay anything for these tests?'
                                    bodyContent={
                                        <span>
                                            Eligible Medicare patients will get these tests at no cost, and their annual
                                            deductible, coinsurance, and copayment don’t apply.{' '}
                                            <Link to='/covid-otc/health-plan'>
                                                <span className='underline'>Click here to see if you qualify</span>
                                            </Link>
                                        </span>
                                    }
                                />
                                <FAQToggleQuestion
                                    className='w-9/12 md:w-[450px]'
                                    headerTitle='How can I get tests through this initiative?'
                                    bodyContent='As a participating pharmacy, we are authorized to provide your tests for you. On the next page you simply enter your contact information, and Medicare ID. Once approved, we will begin shipping your test kits each month. You do not pay for the tests, you will also not be charged for shipping. These tests will automatically be mailed each month absolutely free.'
                                />
                                <FAQToggleQuestion
                                    className='w-9/12 md:w-[450px]'
                                    headerTitle='Can I request more than 8 tests?'
                                    bodyContent={
                                        <span>
                                            If the request exceeds the per calendar month quantity limit of eight tests,
                                            Medicare will deny the claim and no payment will be made to the
                                            participating eligible pharmacy or health care provider for the additional
                                            tests. In addition, Medicare beneficiaries can request free over-the-counter
                                            tests for home delivery at{' '}
                                            <a href='https://www.covid.gov/tests' className='underline'>
                                                covidtests.gov
                                            </a>
                                        </span>
                                    }
                                />
                                <FAQToggleQuestion
                                    className='w-9/12 md:w-[450px]'
                                    headerTitle='How many tests are in a kit?'
                                    bodyContent='Each box contains (2) at-home COVID-19 Antigen Rapid Tests. If eligible to receive the maximum of eight tests, your package will contain (4) kits with with (2) over-the-counter COVID-19 test kits. '
                                />
                                <FAQToggleQuestion
                                    className='w-9/12 md:w-[450px]'
                                    headerTitle='Do I have to switch pharmacies to get a free test?'
                                    bodyContent='No, you can get your free over-the-counter COVID-19 tests from any eligible pharmacy participating in this initiative, even if you aren’t a current customer or patient.'
                                />
                                <FAQToggleQuestion
                                    className='w-9/12 md:w-[450px]'
                                    headerTitle='When does this initiative end?'
                                    bodyContent='This coverage continues until the end of the COVID-19 public health emergency.'
                                />
                            </div>

                            <span
                                className='my-5 block w-full cursor-pointer text-center text-[12pt] underline'
                                onClick={() => setAllQuestions(!allQuestions)}
                            >
                                {allQuestions ? 'Hide questions' : 'See all questions'}
                            </span>
                        </div>
                    </div>
                </div>
            </motion.section>

            <section className='flex items-center justify-center bg-family bg-cover py-[60px] md:py-20'>
                <div className='relative flex aspect-square w-[440px] items-center justify-center'>
                    <img src={CircleSvg} className='absolute top-0 left-0 h-full w-full' alt='' />
                    <div className='relative z-20 flex flex-col items-center px-8 text-center text-white'>
                        <h2 className='text-[26pt]'>Together we can stop the spread</h2>
                        <p className='mt-4 text-[15pt]'>
                            As we navigate changes to our everyday lives as a result of COVID-19, know that your health
                            remains our priority.
                        </p>
                    </div>
                </div>
            </section>

            <Footer />
        </main>
    )
}

const KitsLogo = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width={1000}
        zoomAndPan='magnify'
        viewBox='0 0 750 374.999991'
        height={500}
        preserveAspectRatio='xMidYMid meet'
        className='h-12 w-20'
    >
        <defs>
            <g />
        </defs>
        <g fill='#2B342C' fillOpacity={1}>
            <g transform='translate(15.083422, 310.801587)'>
                <g>
                    <path d='M 8.417969 -221.597656 C 17.511719 -220.925781 30.648438 -226.3125 30.648438 -215.535156 L 30.648438 -33.003906 C 30.648438 -26.941406 27.277344 -21.21875 21.554688 -18.523438 C 19.195312 -17.175781 16.839844 -15.828125 14.816406 -14.480469 C 9.09375 -10.441406 12.460938 0 19.195312 0 L 115.515625 0 C 122.585938 0 125.953125 -10.441406 119.890625 -14.480469 C 117.871094 -15.828125 116.1875 -17.175781 114.167969 -18.1875 C 109.117188 -21.554688 105.410156 -27.277344 105.410156 -33.003906 L 105.410156 -79.816406 C 118.882812 -81.164062 129.320312 -77.796875 138.078125 -51.863281 C 143.804688 -35.363281 147.84375 -24.585938 150.539062 -16.164062 C 154.242188 -6.398438 162.664062 0 172.765625 0 L 238.773438 0 C 245.847656 0 248.878906 -10.441406 243.152344 -14.480469 C 241.132812 -15.828125 238.4375 -16.164062 235.40625 -16.503906 C 230.019531 -16.839844 224.628906 -21.554688 222.609375 -27.953125 L 213.515625 -54.222656 C 202.066406 -88.234375 184.554688 -101.371094 154.242188 -101.371094 C 149.191406 -101.371094 143.804688 -101.03125 137.742188 -100.359375 C 155.253906 -112.820312 164.007812 -128.984375 178.152344 -128.984375 C 193.308594 -128.648438 191.625 -108.441406 217.21875 -106.085938 C 230.691406 -104.738281 242.140625 -115.515625 242.476562 -130.667969 C 243.488281 -154.242188 220.25 -167.039062 198.359375 -166.03125 C 148.519531 -164.347656 148.519531 -121.914062 105.410156 -105.746094 L 105.410156 -217.21875 C 105.410156 -247.53125 94.972656 -251.234375 68.027344 -251.234375 C 46.476562 -251.234375 20.878906 -245.847656 6.0625 -237.425781 C -1.011719 -233.386719 1.011719 -221.933594 8.417969 -221.597656 Z M 8.417969 -221.597656 ' />
                </g>
            </g>
        </g>
        <g fill='#2B342C' fillOpacity={1}>
            <g transform='translate(263.543677, 310.801587)'>
                <g>
                    <path d='M 8.082031 -128.648438 C 12.460938 -128.648438 18.1875 -129.660156 22.5625 -129.660156 C 27.277344 -129.660156 30.648438 -128.3125 30.648438 -122.921875 L 30.648438 -33.003906 C 30.648438 -26.941406 26.941406 -21.21875 21.554688 -18.523438 C 18.859375 -17.175781 16.503906 -15.828125 14.816406 -14.480469 C 9.09375 -10.441406 12.125 0 18.859375 0 L 116.523438 0 C 123.261719 0 126.628906 -10.441406 120.902344 -14.480469 C 118.882812 -15.828125 116.859375 -17.175781 115.175781 -18.1875 C 109.789062 -21.554688 106.421875 -27.277344 106.421875 -33.003906 L 106.421875 -124.605469 C 106.421875 -154.578125 95.644531 -158.621094 69.039062 -158.621094 C 47.484375 -158.621094 20.878906 -153.234375 5.726562 -144.476562 C -1.011719 -140.773438 0.671875 -129.320312 8.082031 -128.648438 Z M 17.511719 -220.925781 C 17.511719 -198.023438 34.351562 -184.554688 62.976562 -184.554688 C 91.601562 -184.554688 108.105469 -198.023438 108.105469 -220.925781 C 108.105469 -243.488281 91.601562 -256.621094 62.976562 -256.621094 C 34.351562 -256.621094 17.511719 -243.488281 17.511719 -220.925781 Z M 17.511719 -220.925781 ' />
                </g>
            </g>
        </g>
        <g fill='#2B342C' fillOpacity={1}>
            <g transform='translate(391.813847, 310.801587)'>
                <g>
                    <path d='M 18.859375 -139.761719 L 37.71875 -139.761719 L 37.71875 -46.8125 C 37.71875 -12.796875 58.261719 4.714844 96.992188 5.386719 C 148.519531 6.0625 161.988281 -14.816406 157.949219 -21.890625 C 156.9375 -23.574219 155.253906 -23.910156 152.894531 -23.910156 C 146.496094 -23.910156 135.046875 -18.523438 126.289062 -18.523438 C 119.21875 -18.523438 113.832031 -21.890625 113.832031 -34.6875 L 113.832031 -139.761719 L 143.804688 -139.761719 C 148.855469 -139.761719 152.222656 -143.128906 152.222656 -148.179688 C 152.222656 -153.570312 148.855469 -156.9375 143.804688 -156.9375 L 113.832031 -156.9375 L 113.832031 -227.324219 C 113.832031 -233.386719 109.789062 -236.753906 103.726562 -235.40625 L 67.691406 -227.996094 C 61.964844 -226.648438 57.589844 -221.933594 57.25 -215.535156 C 54.222656 -187.585938 42.097656 -157.609375 17.175781 -156.9375 L 17.511719 -156.601562 C 13.132812 -156.265625 10.441406 -152.894531 10.441406 -148.519531 L 10.441406 -148.179688 C 10.441406 -143.128906 13.808594 -139.761719 18.859375 -139.761719 Z M 18.859375 -139.761719 ' />
                </g>
            </g>
        </g>
        <g fill='#2B342C' fillOpacity={1}>
            <g transform='translate(552.740636, 310.801587)'>
                <g>
                    <path d='M 6.398438 -30.308594 C 18.859375 -1.011719 72.40625 5.386719 93.625 5.386719 C 153.570312 5.386719 184.554688 -15.828125 182.53125 -53.210938 C 179.839844 -109.789062 69.375 -102.714844 71.058594 -133.363281 C 71.394531 -142.121094 81.5 -146.835938 93.960938 -145.824219 C 126.289062 -143.128906 123.933594 -112.484375 150.539062 -108.777344 C 163.335938 -107.09375 174.113281 -115.515625 171.753906 -128.3125 C 168.722656 -147.507812 140.773438 -160.980469 93.285156 -163 C 40.75 -165.355469 7.074219 -144.476562 7.410156 -114.167969 C 7.746094 -60.28125 117.199219 -50.515625 117.199219 -25.257812 C 117.199219 -15.492188 109.453125 -9.429688 91.941406 -12.125 C 47.820312 -18.859375 58.261719 -56.914062 28.625 -60.957031 C 9.09375 -63.652344 -1.011719 -47.148438 6.398438 -30.308594 Z M 6.398438 -30.308594 ' />
                </g>
            </g>
        </g>
    </svg>
)
