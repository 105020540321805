import { Footer } from '../landing/shared/footer'
import { Header } from './shared/header'

export const CompanyMessage = () => {
    return (
        <div className='flex min-h-screen flex-col'>
            <Header />
            <main className='flex-grow bg-[#2B342C]'>
                <div className='container mx-auto flex flex-col items-center'>
                    <h1 className='mt-24 mb-10 text-[38pt] font-medium text-[#FFF9F2]'>Thank you!</h1>
                    <div className='max-w-[600px] text-center font-thin'>
                        <p className='mb-8 text-[16pt] text-[#F8FAFC] last:mb-0'>
                            Nearly a year ago, we joined the fight to stop the rapid spread of COVID-19. Through those
                            uncertain times, we made it our mission to not only make testing more accessible, but for it
                            to be done in a safe and convenient way.
                        </p>

                        <p className='mb-8 text-[16pt] text-[#F8FAFC] last:mb-0'>
                            Over the course of the year, we’ve helped patients across the country get easy access to
                            at-home COVID-19 testing when they needed it most.
                        </p>

                        <p className='mb-8 text-[16pt] text-[#F8FAFC] last:mb-0'>
                            Along the way, we’ve not only remained consistent and steadfast in our mission, but we’ve
                            discovered how the power of knowledge, unity, and swift action can save lives.
                        </p>

                        <p className='mb-8 text-[16pt] text-[#F8FAFC] last:mb-0'>
                            While we may not be completely out of the woods when it comes to this virus, hope is shining
                            brighter than ever before.
                        </p>

                        <p className='mb-8 text-[16pt] text-[#F8FAFC] last:mb-0'>
                            As we move on to the next chapter, we have exciting changes and expanded services coming
                            your way soon that’ll go well beyond COVID-19.
                        </p>

                        <p className='mb-8 text-[16pt] text-[#F8FAFC] last:mb-0'>
                            First, we’ll be revamping our website to make it more useful and user-friendly. Secondly,
                            we’ll continue to expand our ever-evolving technology and services to better serve you
                            across a wider range of vital healthcare needs (even if you’re located in harder to reach
                            communities).
                        </p>

                        <p className='mb-8 text-[16pt] text-[#F8FAFC] last:mb-0'>
                            As we make this transition, we’ll continue to remain 100% committed to providing quality
                            care to all our patients both now, and in the future. By working closely together, boosting
                            awareness, and increasing accessibility for testing and other essential services, we believe
                            we can make the world a healthier and happier place for everyone.
                        </p>

                        <p className='mb-10 text-[16pt] text-[#F8FAFC]'>- The Kits Team</p>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}
