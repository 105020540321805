import { useLocation, useNavigate } from 'react-router-dom'
import { SUPPORTED_LANGUAGES } from '../app'

export const useNavigateWithLang = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    function doesUrlContainLanguage(pathname: string): boolean {
        return SUPPORTED_LANGUAGES.filter((l: string) => pathname.startsWith('/' + l)).length > 0
    }

    function getLanguageFromURL(pathname: string) {
        return SUPPORTED_LANGUAGES.filter((l: string) => pathname.startsWith('/' + l))[0]
    }

    function navigateWithLang<T>(to: string, state?: T): void {
        const url = getUrlWithLang(to)
        navigate(url, { state })
    }

    function getUrlWithLang(to: string) {
        return `${doesUrlContainLanguage(pathname) ? `/${getLanguageFromURL(pathname)}` : ''}${to}`
    }

    return { navigateWithLang, getUrlWithLang, getLanguageFromURL }
}
